import React from 'react'
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

function Callus() {
    const {t} = useTranslation()
    let bnr1 = require('./../../images/background/bg-finance.jpg');
    let logo = require('./../../images/wolf/logo/loading.png');

    return (
        <>
            <div className="section-full mobile-page-padding p-tb80 overlay-wraper bg-cover bg-parallax bg-center" style={{ backgroundImage: "url(" + bnr1.default + ")" }}>
                <div className="overlay-main bg-primary opacity-07" />
                <div className="container">
                    <div className="section-content">
                        <div className="call-us-section text-center">
                            <h4 className="m-b30"><img src={logo.default} alt='logo' /></h4>
                            <h2 className="call-us-number m-b70">{t('Taahhüdümüz ve motivasyonumuzla gelişmek için bir finansman ortağı bulacaksınız.')}</h2>
                            <NavLink to="/contactus" className="site-button-secondry btn-effect bg-dark">{t('İLETİŞİM')}</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Callus