import React, { useEffect } from 'react';
import Header from './../Common/Header';
import Footer from '../Common/Footer';
import Slider from './../Elements/Slider';
import AboutHome from '../Elements/AboutHome';
import PriceHome from '../Elements/PriceHome';
import Advantage from '../Elements/Advantage';
import Step from '../Elements/Step';
import RegisterForm from '../RegisterForm/RegisterForm';
import { t } from 'i18next';
import { useTranslation } from "react-i18next"

const Home = () => {
    const { t } = useTranslation()
    useEffect(() => {
        { document.title = t('Wolf Markets - Benzersiz Ve Güvenilir Yatırım Partneri') }
    }, [])

    return (
        <>
            <Header />
            <div className="page-content">
                <Slider />
                <AboutHome />
                <Advantage />
                <Step />
                {/* <PriceHome /> */}
            </div>
            <Footer />
            <RegisterForm />
        </>
    );
};

export default Home;