import React, { useEffect } from 'react';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import Banner from '../Elements/Banner';
import AllPrices from '../Elements/AllPrices';
import { useTranslation } from 'react-i18next';
var bnrimg = require('./../../images/wolf/banner/pricelist.webp');

const AllPrice = () => {
    const { t } = useTranslation()

    useEffect(() => {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/masonary.js');

        { document.title = t("Piyasa - Wolf Markets - Benzersiz Ve Güvenilir Yatırım Partneri") }


    }, []);

    return (
        <>
            <Header />
            <div className="page-content">
                <Banner title={t('Tüm ürünlerin canlı fiyat akışlarını takip ederek piyasalardan sürekli haberdar olabilirsiniz.')} pagename={t('Piyasa')} bgimage={bnrimg.default} />
                <div className="section-full">
                    {/* <AllPrices /> */}
                </div>
            </div>

            <Footer />

        </>
    );
};

export default AllPrice;