import React, { useState, useEffect } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import InputMask from 'react-input-mask';
import connector from "../../helpers/connector"
import { useTranslation } from 'react-i18next';

const CreateAccount = () => {
    let brandType = 0;
    var formUrl = window.location.protocol + '//' + window.location.hostname + "/api/v1/Submit?brand=" + brandType;
    // let formUrl = `https://wolf.testmedici.com/api/v1/Submit?brand=${brandType}`

    const { t } = useTranslation()

    const [name, setName] = useState("");
    const [surname, setSurname] = useState("")
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [validator, setValidator] = useState(false);
    const [isNull, setIsNull] = useState(false);
    const [kvkk, setKvkk] = useState(false);
    const [isSend, setIsSend] = useState(false);

    var data = {
        "firstName": name,
        "lastName": surname,
        "email": email,
        "phone": String(phone).replace(" ", "").replace("(", "").replace(")", "").replace(" ", "").replace(" ", ""),
        "approvedConditions": kvkk
    }

    useEffect(() => {
        if (name === "" || surname === "" || email === "" || phone === "" || kvkk === false || phone.includes("_") || validator === false) {
            setIsNull(false)
        }
        else {
            setIsNull(true)
        }
    }, [name, surname, email, phone, validator, kvkk])

    function EmailChange(e) {
        const validEmail = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        let isOk = validEmail.test(String(e).toLowerCase())
        if (isOk) {
            setEmail(e);
            setValidator(true)
        }
        else {
            setEmail(e);
            setValidator(false)
        }
    }

    async function Submit() {
        setIsSend(true)
        connector(formUrl, data)
            .then(data => {
                if (data.success) {
                    NotificationManager.success('İsteğiniz Alındı! Ekibimiz en kısa süre içerisinde sizinle iletişime geçecektir.', '');
                    setIsSend(false);
                }
                else {
                    NotificationManager.error('Bir Hata Oluştu! Lütfen sayfayı yenileyip tekrar deneyin.', '');
                    setIsSend(false)
                }
            })
            .catch(err => {
                if (err.response) {
                    if (err.response.status === 404) {
                        NotificationManager.error('Bir Hata Oluştu! Lütfen sayfayı yenileyip tekrar deneyin.', '');
                        setIsSend(false)
                    }
                    else {
                        NotificationManager.success('İsteğiniz Alındı! Ekibimiz en kısa süre içerisinde sizinle iletişime geçecektir.', '');
                        setIsSend(false)
                    }
                }
                else {
                    NotificationManager.error('Bir Hata Oluştu! Lütfen sayfayı yenileyip tekrar deneyin.', '');
                    setIsSend(false)
                }
            })
        setName("");
        setSurname("");
        setEmail("");
        setPhone("");
    }

    let lsLanguage = localStorage.getItem("i18nextLng")

    return (
        <>
            <NotificationContainer />
            <div className="contact-nav-form p-a30">
                <div className="contact-info m-b30">
                    <div className="contact-form cons-contact-form" method="post" action="">
                        <div className="contact-one m-b10">
                            <div className="section-head">
                                <div className="text-center m-b20">
                                    <h2><span className="text-primary">{t('Gerçek')} </span><br /> <span className="font-weight-300">{t('Hesap Oluştur')}</span></h2>
                                </div>
                            </div>
                            <div className="form-group">
                                <input name="username" type="text" required className="form-control" placeholder={t('İsim')}
                                    value={name} onChange={event => { setName(event.target.value) }} />
                            </div>
                            <div className="form-group">
                                <input name="username" type="text" required className="form-control" placeholder={t('Soyisim')}
                                    value={surname} onChange={event => { setSurname(event.target.value) }} />
                            </div>
                            <div className="form-group">
                                <InputMask mask="(999) 999 99 99" name="phone" type="text" className="form-control" required placeholder={t('Telefon Numarası')}
                                    value={phone} onChange={event => { setPhone(event.target.value) }} />
                            </div>
                            <div className="form-group">
                                <input name="email" type="text" className="form-control" required placeholder="E-MAIL"
                                    value={email} onChange={event => { EmailChange(event.target.value) }} />
                            </div>
                            <div className="form-group">
                                <input type="checkbox" className="form-control" id="kvkk" onClick={() => { setKvkk(kvkk ? false : true) }} />
                                <label htmlFor="kvkk" style={{ maxWidth: "270px" }}>
                                    {
                                        lsLanguage == "tr" ?
                                            <span className='text-primary font-weight-300'><a href='/kvkk' target='_blank' style={{ textDecoration: "bold" }}>Kişisel Verilerin Korunması ve İşlenmesi Sözleşmesi</a>'ni' kabul ediyorum.</span>
                                            :
                                            <span className='text-primary bold font-weight-300'>I accept the Agreement on the <a href='/kvkk' target='_blank' style={{ textDecoration: "underline" }}>Protection and Processing of Personal Data.</a></span>
                                    }
                                </label>
                            </div>
                            <div className="text-center m-t30">
                                {isNull ?
                                    <button name="submit" type="submit" value="Submit" className="site-button btn-effect" onClick={() => { Submit() }}>{t('GÖNDER')}</button>
                                    :
                                    <button name="submit" type="submit" value="Submit" className="site-button btn-effect button-disabled" >{isSend ? `${t('GONDERİLİYOR')}` : `${t('GÖNDER')}`}</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateAccount;

