import { t } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';

var img1 = require('./../../images/left-candle.png');

const Advantage = () => {
    const {t} = useTranslation()

        return (
            <>
                <div className="section-full mobile-page-padding mission-outer-section p-t90 p-b50 bg-no-repeat bg-right-center" style={{ backgroundImage: 'url(' + img1.default, marginRight: '5rem'}}>
                    <div className="section-content">
                        <div className="container">
                            <div className="section-head">
                                <div className="mt-separator-outer separator-center">
                                    <div className="mt-separator">
                                        <h2 className="sep-line-one "><span className="font-weight-400">{t('Avantajları')} </span><span className="text-primary">Wolf Markets</span></h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="">
                                    <div className="mission-left m-b90 p-a30 bg-no-repeat bg-bottom-left" >
                                        <ul className="list-angle-right anchor-line m-b50" style={{fontSize: 18}}>
                                            <li className='m-b20'>{t('7/24 yatırım ve para çekme işlemleri sayesinde kullanıcılarına yüksek hızda işlem yapma imkanı sağlar.')}</li>
                                            <li className='m-b20'>{t('Uzman analistler tarafından yapılan analizlerden yararlanma fırsatı.')}</li>
                                            <li className='m-b20'>{t('Çift yönlü işlem imkanı sunar. Sadece yükselirken değil, düşerken de kazanın.')}</li>
                                            <li className='m-b20'>{t('Düşük işlem maliyetleri ile zengin hesap çeşitliliği sunarak kullanıcı dostudur.')}</li>
                                            <li className='m-b20'>{t('Minimum sermaye ile 200 kata kadar işlem yapma imkanı sağlar.')}</li>
                                            <li className='m-b20'>{t('Yüksek bonuslar ve yatırım bonusu fırsatı ile piyasaya hızlı bir şekilde girme imkanı sağlar.')}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

export default Advantage;