import { t } from 'i18next';
import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import CreateAccount from './CreateAccount';
import RegisterModal from './RegisterModal';
import { useLocation } from "react-router-dom"
var license = require("./../../docs/wolf_license.pdf");
var bnr = require('./../../images/background/bg-5.png');
const Threads = require('../../images/wolf/threads.png');

const Footer = () => {
    const [app, setApp] = useState({
        ios: "",
        android: ""
    })
    var dt = new Date();
    const { t } = useTranslation()
    const [openModal, setOpenModal] = useState(false)
    const [text, setText] = useState()
    const { pathname } = useLocation();


    const handleText = (text) => {
        if (text === 'demo') {
            setText('Demo')
        } else {
            setText('Gerçek')
        }
        setOpenModal(true)
    }

    useEffect(() => {
        getApp()
    }, [])

    const getApp = () => {
        const location = window.location.origin
        let url;
        if (location.includes("localhost") || location.includes("test")) {
            url = "https://wolf.testmedici.com/api/Global/Apps"
        } else {
            url = `${location}/api/Global/Apps`
        }

        fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.json();
            })
            .then(data => {
                setApp({
                    ios: data.ios,
                    android: data.android
                })
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
    }

    return (
        <>
            <footer style={pathname === '/' ? { marginBottom: '14rem' } : null} className="site-footer footer-large footer-light footer-wide">
                <div className="footer-top">
                    <div className="" />
                    <div className="container" style={{ display: "flex", justifyContent: "flex-end" }}>
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="widget widget_about">
                                    <div className="logo-footer clearfix p-b15">
                                        <NavLink to={"./"}><img src={require('./../../images/wolf/logo/logo-dark.png').default} alt="Wolf Markets" /></NavLink>
                                    </div>
                                    <p className="max-w400">{t('Wolf Markets, altyapısı güçlü ve verimli bir aracı kurumdur.')}</p>
                                    <div style={{ maxWidth: '15rem' }}>
                                        <a href={app.android} target='_blank'><img src={require('./../../images/apps/google-play.png').default} alt="google-play-store" /></a>
                                    </div>
                                    <div style={{ maxWidth: '15rem', marginTop: '2rem' }}>
                                        <a href={app.ios} target='_blank'>
                                            <img src={require('./../../images/apps/app-store-logo.png').default} alt="app-store-logo" />
                                        </a>
                                    </div>
                                    <ul className="social-icons  mt-social-links" style={{ marginTop: 20 }}>
                                        <li><a target='_blank' href='https://www.facebook.com/wolfmarketsofc' className="fa fa-facebook m-r10" /></li>
                                        <li><a target='_blank' href='https://x.com/wolfmarketsofc' className="m-r10">
                                            <img width="18" height="18" src="https://img.icons8.com/ios/48/000000/twitterx--v2.png" alt="twitterx--v2" /></a></li>
                                        <li><a target='_blank' href='https://www.instagram.com/wolfmarketsofc' className="fa fa-instagram m-r10" /></li>
                                        <li><a target='_blank' href='https://www.threads.net/@wolfmarketsofc' className="m-r10">
                                            <img src={Threads.default} alt="threads" width="18px" /></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="widget widget_services inline-links">
                                    <h4 className="widget-title">{t('ŞİRKET')}</h4>
                                    <ul>
                                        <li><NavLink to={"/about"}>{t('Hakkımızda')}</NavLink></li>
                                        <li><NavLink to={"/markets"}>{t('Piyasa')}</NavLink></li>
                                        <li><NavLink to={"#"} onClick={() => handleText('demo')}>{t('Demo Hesap Oluştur')}</NavLink></li>
                                        <li><NavLink to={"#"} onClick={() => handleText('gerçek')}>{t('Gerçek Hesap Oluştur')}</NavLink></li>
                                        <li><NavLink to={"/contactus"}>{t('İletişim')}</NavLink></li>
                                        <li><a href="https://connectonline.asic.gov.au/RegistrySearch/faces/landing/SearchRegisters.jspx?_adf.ctrl-state=12omwhyvr7_4#:~:text=View-,Summary,-(PDF)" target="_blank">{t('Lisans')}</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 footer-col-3">
                                <div className="widget widget_services inline-links">
                                    <h4 className="widget-title">{t('SAYFALAR')}</h4>
                                    <ul>
                                        <li><NavLink to={"/what-is-exchange"}>{t('Döviz Alım-Satım')}</NavLink></li>
                                        <li><NavLink to={"/privacy-policy"}>{t('Gizlilik Politikası')}</NavLink></li>
                                        <li><NavLink to={"/crypto-invest"}>{t('Kripto Yatırım İşlemleri')}</NavLink></li>
                                        <li><NavLink to={"/stock-invest"}>{t('Hisse Senedi Yatırımları')}</NavLink></li>
                                        <li><NavLink to={"/risk-alert"}>{t('Risk Uyarısı')}</NavLink></li>
                                        <li><NavLink to={"/cookie-policy"}>{t('Çerez Politikası')}</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="widget widget_services inline-links">
                                    <h4 className="widget-title">{t('İLETİŞİM')}</h4>
                                    <ul>
                                        <li><a href="mailto:support@wolf-markets.com">support@wolf-markets.com</a></li>
                                        <li><a href="tel:+447922274893">+447922274893 </a></li>
                                        <li><a href="https://maps.app.goo.gl/99z8W3BuNJuvcaMU9 " target="_blank">{t('107 Leadenhall St, Londra EC3A 4AF, Birleşik Krallık')}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="" />
                    <div className="container">
                        <div className="row">
                            <div className="container" style={{ display: "flex", justifyContent: "space-between" }}>
                                <span className="copyrights-text">{t('Telif Hakkı © 2023 Wolf Markets. Tüm Hakları Saklıdır.')}</span>
                                <span className="copyrights-text">{t('Wolf Markets, Wolf Markets Hizmetleri LTD. tarafından sahip olunmakta ve işletilmektedir.')}</span>
                            </div>
                        </div>
                    </div>
                </div>

            </footer>
            <RegisterModal openModal={openModal} setOpenModal={setOpenModal} text={text} />
        </>
    );
};

export default Footer;