import React, { useEffect } from 'react';
import Header from './../Common/Header';
import Footer from '../Common/Footer';
import Banner from './../Elements/Banner';
import AboutUs from '../Elements/AboutUs';
import Why from '../Elements/Why';
import Callus from './../Elements/Callus';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

var bnrimg = require('./../../images/wolf/banner/about-us.webp');

const About = () => {
    const { t } = useTranslation()
    useEffect(() => {
        { document.title = t("Hakkımızda - Wolf Markets - Benzersiz Ve Güvenilir Yatırım Partneri") }
    }, [])

    return (
        <>
            <Header />
            <div className="page-content">
                <Banner title={t('Uluslararası kaldıraçlı piyasalar konusunda uzman olan Wolf Markets, hızlı platformu ve profesyonel kadrosu ile 5 gün 24 saat global yatırım piyasasının tüm avantajlarını yatırımcılarına sunmaktadır.')} pagename={t('Hakkımızda')} bgimage={bnrimg.default} />
                <AboutUs />
                <Why />
                <Callus />
            </div>
            <Footer />
        </>
    );
};

export default About;