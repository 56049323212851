import React, { useState, useEffect } from 'react';
import GoogleMaps from "simple-react-google-maps"
import Header from './../Common/Header';
import Footer from '../Common/Footer';
import Banner from './../Elements/Banner';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import InputMask from 'react-input-mask';
import connector from "../../helpers/connector"
import { useTranslation } from 'react-i18next';


var bnrimg = require('./../../images/wolf/banner/contact-us.jpg');

var bgmap = require('./../../images/background/bg-map.png');
var bnr1 = require('./../../images/background/line.png');

const ContactUs = () => {
    let brandType = 0;
    var formUrl = window.location.protocol + '//' + window.location.hostname + "/api/v1/Submit?brand=" + brandType;
    // let formUrl = `https://wolf.testmedici.com/api/v1/Submit?brand=${brandType}`

    const { t } = useTranslation()

    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [validator, setValidator] = useState(false);
    const [isNull, setIsNull] = useState(false);
    const [kvkkContact, setKvkkContact] = useState(false);
    const [isSend, setIsSend] = useState(false);

    var data = {
        "firstName": name,
        "lastName": surname,
        "phone": String(phone).replace(" ", "").replace("(", "").replace(")", "").replace(" ", "").replace(" ", ""),
        "email": email,
        "message": message,
        "approvedConditions": kvkkContact
    }
    // var data = {
    //     domain: window.location.protocol + '//' + window.location.hostname,
    //     formSystemName: "RealAccountForm",
    //     formValues: {
    //         "fullName": fullname,
    //         "email": email,
    //         "phone": String(phone).replace(" ", "").replace("(", "").replace(")", "").replace(" ", "").replace(" ", ""),
    //         "country": "Turkey",
    //         "countryCode": "+90",
    //         "approvedConditions": kvkkContact,
    //         "subject": subject,
    //         "message": message
    //     },
    //     languageIsoCode: 'tr',
    //     siteSystemName: 'Wolf Markets',

    // }

    useEffect(() => {
        { document.title = t("İletişim - Wolf Markets - Benzersiz Ve Güvenilir Yatırım Partneri") }
    }, [])


    useEffect(() => {
        if (name === "" || email === "" || phone === "" || surname === "" || message === "" || kvkkContact === false || phone.includes("_") || validator === false) {
            setIsNull(false)
        }
        else {
            setIsNull(true)
        }
    }, [name, email, phone, surname, message, validator, kvkkContact])

    function EmailChange(e) {
        const validEmail = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        let isOk = validEmail.test(String(e).toLowerCase())
        if (isOk) {
            setEmail(e);
            setValidator(true)
        }
        else {
            setEmail(e);
            setValidator(false)
        }
    }

    async function Submit() {
        setIsSend(true)
        connector(formUrl, data)
            .then(data => {
                if (data.success) {
                    NotificationManager.success('Mesajınız Alındı! Ekibimiz en kısa süre içerisinde sizinle iletişime geçecektir.', '');
                    setIsSend(false);
                }
                else {
                    NotificationManager.error('Bir Hata Oluştu! Lütfen sayfayı yenileyip tekrar deneyin.', '');
                    setIsSend(false)
                }
            })
            .catch(err => {
                if (err.response) {
                    if (err.response.status === 404) {
                        NotificationManager.error('Bir Hata Oluştu! Lütfen sayfayı yenileyip tekrar deneyin.', '');
                        setIsSend(false)
                    }
                    else {
                        NotificationManager.success('Mesajınız Alındı! Ekibimiz en kısa süre içerisinde sizinle iletişime geçecektir.', '');
                        setIsSend(false)
                    }
                }
                else {
                    NotificationManager.error('Bir Hata Oluştu! Lütfen sayfayı yenileyip tekrar deneyin.', '');
                    setIsSend(false)
                }
            })
        setName("");
        setEmail("");
        setPhone("");
        setSurname("");
        setMessage("");
    }

    let lsLanguage = localStorage.getItem("i18nextLng")

    return (
        <>
            <Header />
            <NotificationContainer />
            <div className="page-content">
                <Banner title={t('Aklınıza takılan her türlü soru, görüş ve önerileriniz için bizimle iletişime geçebilirsiniz.')} pagename={t('İLETİŞİM')} bgimage={bnrimg.default} />
                <div className="section-full p-tb80 inner-page-padding" style={{ backgroundImage: "url(" + bgmap.default + ")" }}>
                    <div className="container">
                        <div className="section-content">
                            <div className="row">
                                <div className="col-md-7 col-sm-6">
                                    <div className="contact-form cons-contact-form" method="post" action="">
                                        <div className="contact-one m-b30">
                                            <div className="section-head">
                                                <div className="mt-separator-outer separator-left">
                                                    <div className="mt-separator">
                                                        <h2 className="sep-line-one "><span className="font-weight-300 text-primary">{t('İletişim')}</span> {t('Formu')}</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <input name="name" type="text" required className="form-control" placeholder={t('İsim')}
                                                    value={name} onChange={event => { setName(event.target.value) }} />
                                            </div>
                                            <div className="form-group">
                                                <input name="surname" type="text" className="form-control" required placeholder={t('Soyisim')}
                                                    value={surname} onChange={event => { setSurname(event.target.value) }} />
                                            </div>
                                            <div className="form-group">
                                                <InputMask mask="(999) 999 99 99" name="phone" type="text" className="form-control" required placeholder={t('Telefon Numarası')}
                                                    value={phone} onChange={event => { setPhone(event.target.value) }} />
                                            </div>
                                            <div className="form-group">
                                                <input name="email" type="text" className="form-control" required placeholder={t('E-mail')}
                                                    value={email} onChange={event => { EmailChange(event.target.value) }} />
                                            </div>
                                            <div className="form-group">
                                                <textarea name="message" rows={4} className="form-control " required placeholder={t('Mesaj')} defaultValue={""}
                                                    value={message} onChange={event => { setMessage(event.target.value) }} />
                                            </div>
                                            <div className="form-group">
                                                <input type="checkbox" className="form-control" id="kvkkContact" onClick={() => { setKvkkContact(kvkkContact ? false : true) }} />
                                                <label htmlFor="kvkkContact">
                                                    {
                                                        lsLanguage == "tr" ?
                                                            <span className='text-primary font-weight-300'><a href='/kvkk' target='_blank' style={{ textDecoration: "underline" }}>Kişisel Verilerin Korunması ve İşlenmesi Sözleşmesi</a>'ni' kabul ediyorum.</span>
                                                            :
                                                            <span className='text-primary font-weight-300'>I accept the Agreement on the <a href='/kvkk' target='_blank' style={{ textDecoration: "underline" }}>Protection and Processing of Personal Data.</a></span>
                                                    }
                                                </label>
                                            </div>
                                            <div className="text-right m-t50">
                                                {isNull ?
                                                    <button name="submit" type="submit" value="Submit" className="site-button btn-effect" onClick={() => { Submit() }}>{t('GONDER')}</button>
                                                    :
                                                    <button name="submit" type="submit" value="Submit" className="site-button btn-effect button-disabled" >{isSend ? `${t('GONDERILIYOR')}` : `${t('GONDER')}`}</button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 col-sm-6">
                                    <div className="contact-info m-b30">
                                        <div className="section-head">
                                            <div className="mt-separator-outer separator-left">
                                                <div className="mt-separator">
                                                    <h2 className="sep-line-one "><span className="font-weight-300 text-primary">{t('İletişim')}</span> {t('Bilgileri')}</h2>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="m-about-years m-b20">
                                            <span className="text-primary large-title-info">{t('Telefon Numarası')}</span>
                                            <h5 className="large-title"><a href="tel:+447922274893" style={{ cursor: "pointer" }}>+447922274893</a></h5>
                                        </div>
                                        <div className="m-about-years m-b20">
                                            <span className="text-primary large-title-info">E - Mail</span>
                                            <h5 className="large-title"><a href="mailto:support@wolf-markets.com" style={{ cursor: "pointer" }}>support@wolf-markets.com</a></h5>
                                        </div>
                                        <div className="m-about-years m-b20">
                                            <span className="text-primary large-title-info">{t('Adres')}</span>
                                            <h5 className="large-title"><a href="https://maps.app.goo.gl/99z8W3BuNJuvcaMU9" target="_blank" style={{ cursor: "pointer" }}>{t('107 Leadenhall St, Londra EC3A 4AF, Birleşik Krallık')}</a></h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="gmap-outline m-t50">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39727.697109488414!2d-0.12183753046874995!3d51.51356329999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487603ce67f97fa5%3A0xedca677e57feb64c!2sProspect%20Business%20Centres!5e0!3m2!1sen!2sbg!4v1706864431213!5m2!1sen!2sbg" width="100%" height={450} style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ContactUs;