import React from 'react';
import Router from './router/Router';
import Loader from "./components/Elements/Loader";
import "./index.css"

const App = () => {
  
  return (
    <div>
      <Router />  
      <Loader/>  
    </div>
  );
}

export default App;