import React from 'react'
import { useTranslation } from 'react-i18next'
import Footer from '../Common/Footer'
import Header from '../Common/Header'
import Banner from '../Elements/Banner'
import { useEffect } from 'react'
var bnrimg = require('./../../images/wolf/banner/about-us.webp');

function ForexAnalytics() {
  const { t } = useTranslation()

  useEffect(() => {
    { document.title = t("Hisse Senedi Yatırımları - Wolf Markets - Benzersiz Ve Güvenilir Yatırım Partneri") }
  }, [])

  return (
    <>
      <Header />
      <div className="page-content">
        <Banner title={t("Hisse Senedi Yatırımları")} pagename={t('Hisse Senedi Yatırımları')} bgimage={bnrimg.default} />
        <div style={{ marginLeft: '5rem', marginRight: '5rem' }} className='text-center'>
          <h4 style={{ marginTop: '4rem' }}>{t('Hisse Senetleri: Küresel Yatırımcıların Gözdesi')}</h4>
          <p>{t("Hisse senetleri, yatırımcılar arasında uzun yıllardır popülerliğini koruyan ve finansal başarıya ulaşmanın yolu olarak kabul edilen önemli bir yatırım aracıdır. Hem Türkiye'de hem de dünya genelinde yatırımcıların ilgisini çeken hisse senetleri, birçok açıdan çekicidir.")}</p>

          <h4 style={{ marginTop: '4rem' }}>{t('Hisse Senetleri Nedir?')}</h4>
          <p>{t("Hisse senetleri, bir şirketin ortaklarına ait sahiplik paylarını temsil eden finansal araçlardır. Bir hisse senedi sahibi olarak, ilgili şirkete sahip olduğunuzu gösterirsiniz. Bu sahiplik, şirket karlarını pay alma, oy kullanma hakkını içerebilir ve yatırımcılara şirketin başarısından doğrudan pay verir. Hisse senetleri, yatırımcılara şirketlerin büyüme potansiyeline ve karlılığına katılmaları için bir fırsat sunar.")}</p>


          <h4 style={{ marginTop: '4rem' }}>{t('Hisse Senetleri Neden Popülerdir?')}</h4>
          <p>{t("Hisse senetleri, yatırımcılar arasında neden bu kadar popülerdir? İşte bazı nedenler:")}</p>
          <ul style={{listStyleType:"none"}}>
            <li><span style={{fontWeight:"bold"}}>{t("1.Büyüme Potansiyeli:")}</span>{t("Hisse senetleri, yüksek büyüme potansiyeli sunan şirketlere yatırım yapma fırsatı sunar. Bu potansiyel, yatırımcılara sermayelerini artırma ve servetlerini büyütme şansı verir.")} </li>
            <li><span style={{fontWeight:"bold"}}>{t("2.Portföy Çeşitlendirmesi: ")}</span>{t("Hisse senetleri, portföyünü çeşitlendirmek isteyen yatırımcılar için idealdir. Farklı sektörlerden hisse senetleri ekleyerek riski dağıtmak mümkün olur.")}</li>
            <li><span style={{fontWeight:"bold"}}>{t("3.Likit Varlıklar: ")}</span>{t("Hisse senetleri genellikle likittir, yani kolayca alınıp satılabilirler. Bu, yatırımcıların ihtiyaç duyduklarında varlıklarını nakde çevirmelerine olanak tanır.")}</li>
          </ul>



          <h4 style={{ marginTop: '4rem' }}>{t('Yatırımcıların Hisse Senedi Tercihi')}</h4>
          <p>{t("Yatırımcılar, hisse senetlerini neden tercih ediyorlar? İşte bazı nedenler:")}</p>
          <ul style={{listStyleType:"none"}}>
            <li><span style={{fontWeight:"bold"}}>{t("1.	Uzun Vadeli Büyüme: ")}</span>{t("Birçok yatırımcı, hisse senetlerine uzun vadeli bir bakış açısıyla yaklaşır. Hisse senetlerinin değeri zaman içinde artabilir ve bu nedenle uzun vadeli yatırım fırsatları sunarlar.")} </li>
            <li><span style={{fontWeight:"bold"}}>{t("2.	Kar Payları: ")}</span>{t("Hisse senedi yatırımcıları, şirketlerin kar paylarını alma hakkına sahiptirler. Bu, yıllık olarak temettü alarak pasif gelir elde etmek isteyen yatırımcılar için çekicidir.")}</li>
            <li><span style={{fontWeight:"bold"}}>{t("3.	Yönetim Kontrolü: ")}</span>{t("Hisse senedi sahipleri, genellikle şirketin yönetimine oy kullanma hakkına sahiptirler. Bu, şirketin yönetimine katılma ve kararlarını etkileme fırsatı sunar.")}</li>
          </ul>

          <h4 style={{ marginTop: '4rem' }}>{t('Hisse Senetlerinin Popülerliği')}</h4>
          <p>{t("Hisse senetleri, yatırımcılar arasında uzun yıllardır popülerlik kazanmıştır ve bu eğilim devam etmektedir. Yatırımcılar, hisse senetlerini uzun vadeli büyüme, kar payları ve portföy çeşitlendirmesi gibi avantajlar nedeniyle tercih ediyorlar. Ancak unutulmamalıdır ki hisse senetleri, her zaman risk içerir ve piyasa koşulları değişebilir. Bu nedenle, yatırımdan önce dikkatli bir şekilde araştırma yapmak ve uzman tavsiyelerinden faydalanmak önemlidir.")}</p>

         
        </div>
      </div>
      <Footer />
    </>
  )
}

export default ForexAnalytics