import React, { useState, useEffect } from 'react';
import Navigation from '../Common/Navigation';
import { NavLink } from 'react-router-dom';
import CreateAccount from './CreateAccount';
import { useTranslation } from 'react-i18next';

var bnr = require('./../../images/background/bg-5.png');

const Header = () => {


    const [state, setState] = useState({ isSearchActive: false, isQuoteActive: false });
    const [lang, setLang] = useState("TR");
    const { t, i18n } = useTranslation()

    const handleQuoteToggle = () => {
        setState({ isQuoteActive: !state.isQuoteActive });
    };


    let sca = 'https://sca.' + (window.location.hostname.replace('www.', ''))

    // useEffect(() => {

    //     const handleScroll = () => {
    //         const offset = window.scrollY;

    //         const stickyheader = document.querySelector('.sticky-header ');
    //         if (offset >= 100) {
    //             stickyheader.classList.add('is-fixed');
    //             stickyheader.classList.add('color-fill');

    //         } else {
    //             stickyheader.classList.remove('is-fixed');
    //             stickyheader.classList.remove('color-fill');
    //         }
    //     }

    //     window.addEventListener('scroll', handleScroll);

    // }, [])

    const changeLanguage = () => {
        if (i18n.language === 'tr') {
            i18n.changeLanguage('en');
            setLang("ENG")
        } else {
            i18n.changeLanguage('tr');
            setLang("TR")

        }
    };

    const isQuoteActive = state.isQuoteActive;

    return (
        <>

            <header className="site-header header-style-1">
                <div className="top-bar bg-gray">
                    <div className="container">
                        <div className="row">
                            <div className="mt-topbar-left clearfix ">
                                <ul className="list-unstyled e-p-bx pull-right">
                                    <li><a className='topmenulink' href="mailto:support@wolf-markets.com"><i className="fa fa-envelope" /> support@wolf-markets.com</a></li>
                                    <li><a className='topmenulink' href="tel:+447922274893"><i className="fa fa-phone" />+447922274893</a></li>
                                    <li><a className='topmenulink' href="https://api.whatsapp.com/send?phone=447922274893" target="_blank"><i className="fa fa-whatsapp" />WhatsApp</a></li>
                                </ul>
                            </div>
                            <div className="mt-topbar-right clearfix">
                                <div className="appint-btn">
                                    <NavLink to={"/"} onClick={() => changeLanguage()} className="site-button"><img src={lang === "TR" ? require('./../../images/language/tr.png').default : require('./../../images/language/en.png').default} alt="Wolf Markets" /></NavLink>
                                    <NavLink to={"#"} className="site-button" onClick={handleQuoteToggle}>{t('KAYIT OL')}</NavLink>
                                    <NavLink to={{ pathname: `${sca}` }} target="_blank" className="site-button">{t('GİRİŞ YAP')}</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sticky-header main-bar-wraper">
                    <div className="main-bar bg-white">
                        <div className="container">
                            <div className="logo-header">
                                <div className="logo-header-inner logo-header-one">
                                    <NavLink to={"/"}>
                                        <img src={require('./../../images/wolf/logo/logo-dark.png').default} alt="Wolf Markets" />
                                    </NavLink>
                                </div>
                            </div>
                            <button data-target=".header-nav" data-toggle="collapse" type="button" className="navbar-toggle collapsed">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar" />
                                <span className="icon-bar" />
                                <span className="icon-bar" />
                            </button>
                            <div className="contact-slide-hide " style={{ backgroundImage: 'url(' + bnr.default + ')', right: isQuoteActive ? '0px' : '-500px' }}>
                                <div className="contact-nav">
                                    <div className="contact-nav">
                                        <NavLink to={"#"} className="contact_close" onClick={handleQuoteToggle}>×</NavLink>
                                        <CreateAccount />
                                    </div>
                                </div>

                            </div>
                            <Navigation />
                        </div>
                    </div>
                </div>

            </header>

        </>
    );
};

export default Header;

