import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom';
import Header from './../Common/Header';
import Footer from '../Common/Footer';

const Error = () => {

    const { t } = useTranslation()
    useEffect(() => {
        { document.title = t("Sayfa Bulunamadı - Wolf Markets - Benzersiz Ve Güvenilir Yatırım Partneri") }
    }, [])

    return (
        <>
            <Header />
            <div className="page-content">
                <div className="section-full p-tb150">
                    <div className="container">
                        <div className="section-content">
                            <div className="page-notfound text-center">
                                <strong>Page Not Found!</strong>
                                <span className="title">404</span>
                                {/* <p>Aradığınız web sayfası bulunamamaktadır.</p> */}
                                <NavLink to="/" title="Go Home" className="site-button btn-effect m-t50">Go Home</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Error;