import React from 'react'
import { useTranslation } from 'react-i18next'
import Footer from '../Common/Footer'
import Header from '../Common/Header'
import Banner from '../Elements/Banner'
import { useEffect } from 'react'
var bnrimg = require('./../../images/wolf/banner/about-us.webp');

export default function CookiePolicy() {
    const { t } = useTranslation()

    useEffect(() => {
        { document.title = t("Kişisel Verilerin Korunması Kanunu - Wolf Markets - Benzersiz Ve Güvenilir Yatırım Partneri") }
    }, [])

    return (
        <>
            <Header />
            <div className="page-content">
                <Banner title={t("Kişisel Verilerin Korunması Kanunu")} pagename={t('Kişisel Verilerin Korunması Kanunu')} bgimage={bnrimg.default} />
                <div style={{ marginLeft: '5rem', marginRight: '5rem' }} className='text-center'>
                    <h4 style={{ marginTop: '4rem' }}>{t('Wolf Markets Kişisel Verilerin Korunması Kanunu (KVKK) Metni')}</h4>
                    <p>{t('Wolf Markets olarak, müşterilerimizin gizlilik haklarına saygı duyuyor ve kişisel verilerin korunması konusuna büyük önem veriyoruz.Bu nedenle, Kişisel Verilerin Korunması Kanunu`na uygun olarak, kişisel verilerinizi toplamadan, işlemeden, saklamadan ve korumadan önce size bilgi vermek istiyoruz.')}</p>
                    <h4 style={{ marginTop: '4rem' }}>{t('Veri Sorumlusu ve İletişim Bilgileri')}</h4>
                    <p>{t('Wolf Markets olarak, kişisel verilerinizin işlenmesinden sorumlu olan veri sorumlusuyuz. Aşağıda iletişim bilgilerimizi bulabilirsiniz:')}</p>
                    <p>{t('Veri Sorumlusu')}:</p>
                    <p>{t('Adres')}: {t('107 Leadenhall St, Londra EC3A 4AF, Birleşik Krallık')}</p>
                    <p>{t('E-posta')}: support@wolf-markets.com</p>
                    <p>{t('Telefon')}: +447922274893</p>
                    <h4 style={{ marginTop: '4rem' }}>{t('Kişisel Verilerin İşlenme Amacı ve Hukuki Dayanağı')}</h4>
                    <p>{t('Kişisel verilerinizi aşağıdaki amaçlarla işlemekteyiz')}:</p>
                    <p><b>{t('Müşteri hesaplarının oluşturulması ve yönetilmesi')}</b></p>
                    <p><b>{t('Finansal işlemlerin gerçekleştirilmesi')}</b></p>
                    <p><b>{t('Müşteri destek hizmetlerinin sağlanması')}</b></p>
                    <p><b>{t('Yasal yükümlülüklerin yerine getirilmesi')}</b></p>
                    <p><b>{t('Ürün ve hizmetlerimizin geliştirilmesi ve pazarlanması')}</b></p>
                    <p>{t('Kişisel verilerinizin işlenmesi, yukarıda belirtilen amaçların yerine getirilmesi için gerekli olan hukuki dayanaklara dayanmaktadır.Bunlar arasında sözleşmenin ifası, yasal yükümlülüklerin yerine getirilmesi, meşru çıkarlarımızın korunması ve açık rıza bulunmaktadır.')}</p>
                    <h4 style={{ marginTop: '4rem' }}>{t('Kişisel Verilerin İşlenme Süresi ve Saklama Politikası')}</h4>
                    <p>{t('Kişisel verilerinizi yalnızca amaçlarla sınırlı bir şekilde ve ilgili mevzuata uygun olarak işlemekteyiz.Verileriniz, ilgili amaçlar için gerekli olan süre boyunca saklanacak ve daha sonra silinecektir.Bununla birlikte, bazı verilerin yasal saklama sürelerine veya diğer hukuki gerekliliklere tabi olabileceğini unutmayınız.')}</p>
                    <h4 style={{ marginTop: '4rem' }}>{t('Kişisel Verilerin Paylaşımı ve Aktarımı')}</h4>
                    <p>{t('Kişisel verilerinizi, hizmetlerimizin sunumu için gerektiğinde, yasalara uygun şekilde iş ortaklarımızla veya yetkili mercilerle paylaşabiliriz. Wolf Markets olarak, kişisel verilerinizin güvenliğini ve gizliliğini sağlamak için gerekli teknik ve organizasyonel önlemleri almaktayız.')}</p>
                    <h4 style={{ marginTop: '4rem' }}>{t('Kişisel Verilerin Hakları')}</h4>
                    <p>{t('KVKK kapsamında, kişisel verilerinizle ilgili çeşitli haklara sahipsiniz. Bu haklar arasında kişisel verilere erişim, düzeltme, silme, işleme sınırlama, veri taşınabilirliği ve itiraz etme hakları bulunmaktadır. Bu haklarınızı kullanmak veya veri işleme faaliyetlerimizle ilgili herhangi bir konuda sorularınızı sormak için yukarıda belirtilen iletişim bilgilerimizi kullanabilirsiniz.')}</p>
                    <h4 style={{ marginTop: '4rem' }}>{t('KVKK Metninde Değişiklikler')}</h4>
                    <p>{t('Wolf Markets olarak, KVKK metnimizi zaman zaman güncelleyebiliriz. Bu nedenle, güncel bir metni her zaman web sitemizde veya müşterilerimize iletişim yoluyla sunabiliriz. Metin üzerinde yapılan değişiklikler hakkında size bildirimde bulunabiliriz.')}</p>
                </div>
            </div>
            <Footer />
        </>
    )
}