import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Footer from '../Common/Footer'
import Header from '../Common/Header'
import Banner from '../Elements/Banner'
var bnrimg = require('./../../images/wolf/banner/about-us.webp');

export default function CookiePolicy() {
  const { t } = useTranslation()

  useEffect(() => {
    { document.title = t("Çerez Politikası - Wolf Markets - Benzersiz Ve Güvenilir Yatırım Partneri") }
  }, [])

  return (
    <>
      <Header />
      <div className="page-content">
        <Banner title={t("Çerez Politikası")} pagename={t('Çerez Politikası')} bgimage={bnrimg.default} />
        <div style={{ marginLeft: '5rem', marginRight: '5rem' }} className='text-center'>
          <h4 style={{ marginTop: '4rem' }}>{t('Çerezler')}</h4>
          <p>{t("Günümüzde neredeyse her web sitesi çerez kullanmaktadır. Size daha iyi, hızlı ve güvenli bir deneyim sağlamak için, çoğu internet sitesi gibi biz de çerezler kullanıyoruz. Çerez, bir web sitesini ziyaret ettiğinizde cihazınıza (örneğin; bilgisayar veya cep telefonu) depolanan küçük bir metin dosyasıdır. Çerezler, bir web sitesini ilk ziyaretiniz sırasında tarayıcınız aracılığıyla cihazınıza depolanabilirler. Aynı siteyi aynı cihazla tekrar ziyaret ettiğinizde tarayıcınız cihazınızda site adına kayıtlı bir çerez olup olmadığını kontrol eder. Eğer kayıt var ise, kaydın içindeki veriyi ziyaret etmekte olduğunuz web sitesine iletir. Bu sayede web sitesi, sizin siteyi daha önce ziyaret ettiğinizi anlar ve size iletilecek içeriği de ona göre tayin eder. İnternet Sitemizde çerez kullanılmasının başlıca amaçları aşağıda sıralanmaktadır:")}</p>
          <p>{t("İnternet Sitemizde çerez kullanılmasının başlıca amaçları aşağıda sıralanmaktadır:Site’nin ve uygulamanın çalışması için gerekli temel fonksiyonları gerçekleştirmek. Örneğin, oturum açan üyelerin Site ’de farklı sayfaları ziyaret ederken tekrar şifre girmelerine gerek kalmaması.")}</p>
          <ul>
            <li>{t('Siteyi ve uygulamayı analiz etmek, Site’nin ve uygulamanın performansını arttırmak. Örneğin, Site’nin üzerinde çalıştığı farklı sunucuların entegrasyonu, Siteyi ziyaret edenlerin sayısının tespit edilmesi ve buna göre performans ayarlarının yapılması ya da ziyaretçilerin aradıklarını bulmalarının kolaylaştırılması.')}</li>
            <li>{t('Site’nin ve uygulamanın işlevselliğini arttırmak ve kullanım kolaylığı sağlamak. Örneğin, Site üzerinden üçüncü taraf sosyal medya mecralarına paylaşımda bulunmak, Siteyi ziyaret eden ziyaretçinin daha sonraki ziyaretinde kullanıcı adı bilgisinin ya da arama sorgularının hatırlanması.')}</li>
            <li>{t('Kişiselleştirme, hedefleme ve reklamcılık faaliyeti gerçekleştirmek. Örneğin, ziyaretçilerin görüntüledikleri sayfa ve ürünler üzerinden ziyaretçilerin ilgi alanlarıyla bağlantılı reklam gösterilmesi.')}</li>
          </ul>
          <h4 style={{ marginTop: '4rem' }}>{t("Çerez türleri")}</h4>
          <p>{t("Web sitelerimizi ziyaret ettiğinizde aşağıdaki çerez türleri kullanılabilir.")}</p>
          <h5 style={{ marginTop: '4rem' }}>{t("Oturum Çerezleri")}</h5>
          <p>{t("Oturum çerezleri ziyaretçilerimizin İnternet Sitesini ziyaretleri süresince kullanılan, tarayıcı kapatıldıktan sonra silinen geçici çerezlerdir.Bu tür çerezlerin kullanılmasının temel amacı ziyaretiniz süresince İnternet Sitesinin düzgün bir biçimde çalışmasının teminini sağlamaktır.")}</p>
          <h5 style={{ marginTop: '4rem' }}>{t("Kalıcı Çerezler")}</h5>
          <p>{t("Kalıcı çerezler İnternet Sitesinin işlevselliğini artırmak, ziyaretçilerimize daha hızlı ve iyi bir hizmet sunmak amacıyla kullanılan çerez türleridir. Bu tür çerezler tercihlerinizi hatırlamak için kullanılır ve tarayıcılar vasıtasıyla cihazınızda depolanır.")}</p>
          <p>{t("Kalıcı çerezlerin bazı türleri; İnternet Sitesini kullanım amacınız gibi hususlar göz önünde bulundurarak sizlere özel öneriler sunulması için kullanılabilmektedir.")}</p>
          <p>{t("Kalıcı çerezler sayesinde İnternet Sitemizi aynı cihazla tekrardan ziyaret etmeniz durumunda, cihazınızda İnternet Sitemiz tarafından oluşturulmuş bir çerez olup olmadığı kontrol edilir ve var ise, sizin siteyi daha önce ziyaret ettiğiniz anlaşılır ve size iletilecek içerik bu doğrultuda belirlenir ve böylelikle sizlere daha iyi bir hizmet sunulur.")}</p>
          <h5 style={{ marginTop: '4rem' }}>{t("Otantikasyon Çerezleri")}</h5>
          <p>{t("Ziyaretçiler, şifrelerini kullanarak internet sitesine giriş yapmaları durumunda, bu tür çerezler ile, ziyaretçinin internet sitesinde ziyaret ettiği her bir sayfada site kullanıcısı olduğu belirlenerek, kullanıcının her sayfada şifresini yeniden girmesi önlenir.")}</p>
          <h5 style={{ marginTop: '4rem' }}>{t("Analitik Çerezler")}</h5>
          <p>{t("Analitik çerezler ile internet sitesini ziyaret edenlerin sayıları, internet sitesinde görüntülenen sayfaların tespiti, internet sitesi ziyaret saatleri, internet sitesi sayfaları kaydırma hareketleri gibi analitik sonuçların üretimini sağlayan çerezlerdir.")}</p>
          <h5 style={{ marginTop: '4rem' }}>{t("Kullanılan Çerezler ve Saklama Süreleri")}</h5>
          <p>{t("Web sitemizde yalnızca 3. Taraf çerezleri kullanılmaktadır. Aşağıdaki tabloda kullanılan çerezler ve kullanım süreleriyle ilgili detaylar yer almaktadır.")}</p>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">{t('Çerezin Adı')}</th>
                <th className='text-center' scope="col">{t('Kullanım Amacı')}</th>
                <th scope="col">{t('Kullanım Süresi')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">{t('Google Analytics')}</th>
                <td>{t("Google Analytics, çerezleri ziyaretçilerin Web Sitesini nasıl kullandıklarını analiz etmek amacıyla kullanır. Bu Web Sitesini kullanımınızla ilgili bilgiler (IP adresiniz dahil) Google'a aktarılarak Google tarafından ABD'deki sunucularda saklanmaktadır. Google bu bilgileri Web Sitesini kullanımınızı değerlendirmek, Şirket için Web Sitesi faaliyetini derlemek ve Web Sitesi faaliyeti ve internet kullanımıyla ilgili başka hizmetler sağlamak amacıyla kullanacaktır, fakat IP adresinizi Google tarafından depolanan diğer verilerle eşleştirmeyecektir. Google Analytics kullanımı hakkında daha fazla bilgi için (reddetme seçenekleri dahil), şu adresi ziyaret edebilirsiniz: https://marketingplatform.google.com/about/")}</td>
                <td>{t('Kalıcı Çerezler, Oturum Çerezleri')}</td>
              </tr>
              <tr>
                <th scope="row">{t('Google Ads')}</th>
                <td>{t("Google Ads	Google Ads, çerezleri, güvenli arama tercihlerinizi hatırlamak, size daha alakalı reklamlar göstermek, bir sayfaya kaç ziyaretçi geldiğini belirlemek, hizmetlerimize kaydolmanıza yardımcı olmak, verilerinizi korumak veya reklam ayarlarınızı hatırlamak için kullanır. Bu, web sitesinin ziyaretinizle ilgili bilgileri (örneğin, tercih ettiğiniz dil ve diğer ayarlar) hatırlamasına yardımcı olur. Bu Web Sitesini kullanımınızla ilgili bilgiler (IP adresiniz dahil) Google'a aktarılarak Google tarafından ABD'deki sunucularda saklanmaktadır. Google bu bilgileri Web Sitesini kullanımınızı değerlendirmek, Şirket için Web Sitesi faaliyetini derlemek ve Web Sitesi faaliyeti ve internet kullanımıyla ilgili başka hizmetler sağlamak amacıyla kullanacaktır, fakat IP adresinizi Google tarafından depolanan diğer verilerle eşleştirmeyecektir. Google Ads çerez kullanımı hakkında daha fazla bilgi için (reddetme seçenekleri dahil), şu adresi ziyaret edebilirsiniz: https://policies.google.com/privacy?hl=tr")}</td>
                <td>{t('Kalıcı Çerezler, Oturum Çerezleri')}</td>
              </tr>
              <tr>
                <th scope="row">{t('RMC')}</th>
                <td>{t("RMC	RMC, web tarayıcısı aracılığıyla bilgisayarınıza veya üçüncü taraf bir analiz sağlayıcısına ait benzersiz bir tanımlayıcı içeren küçük metin dosyaları olan 'çerezleri' kullanır. Bunu hizmetleri ve web sitelerini geliştirmek için kullanır. RMC, belirli pazarlama kampanyalarının ve bazı ürünlerinin performansını izlemek için Google Analytics'i kullanır. Bu, üçüncü taraf hizmet sağlayıcıların, farklı temas noktalarında RMC adına uyarlanmış reklamlar oluşturmasına izin vermektir. Bu servis sağlayıcılar, kişisel bilgilerinizi, iletişim bilgilerinizi vb. İçermeyen IP adresinizi ve kişisel olarak tanımlanamayan bilgilerinizi toplayabilir. Bu çerezler ayrıca web sitemizin nasıl kullanıldığına ve performansına ilişkin istatistiksel bilgiler de sağlar. RMC çerez kullanımı hakkında daha fazla bilgi için (reddetme seçenekleri dahil), şu adresi ziyaret edebilirsiniz: Related Digital Privacy https://www.relateddigital.com/en/rmc-product-privacy-policy/")}</td>
                <td>{t('Kalıcı Çerezler, Oturum Çerezleri')}</td>
              </tr>
              <tr>
                <th scope="row">{t('Facebook')}</th>
                <td>{t("Facebook çerezleri, facebook hesabınız varsa, site ve uygulamalar dahil olmak üzere Facebook ürünlerini kullanırsanız veya Facebook ürünlerini kullanan siteleri veya uygulamaları (Beğen düğmesi ve diğer Facebook Teknolojileri dahil) ziyaret ederseniz, kullanır. Çerezler, Facebook'un Facebook ürünlerini size sunmasını ve kayıtlı veya giriş yapmış olup olmamanızdan bağımsız olarak, diğer internet siteleri ve uygulamaları kullanımınız dahil olmak üzere sizin hakkında aldığı bilgileri anlamasını sağlar. Çerezleri; kimlik kontrolü, güvenlik ve site bütünlüğü, reklamlar, tavsiyeler, ölçüm, analiz ve raporlama için kullanır.Ayrıca ilginizi çekebilecek reklamları sosyal medya platformlarında da sunabilmek ve özel hedef kitle oluşturmak amacıyla kişisel verilerinizden e-posta adresiniz bu platformlar ile paylaşılmaktadır. E-posta adresiniz, bu platformların sunduğugüvenli kanallar ve ortamlar üzerinden aktarılmaktadır.")}</td>
                <td>{t('Kalıcı Çerezler, Oturum Çerezleri')}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </>
  )
}