import React, { useEffect, useState } from 'react';
import priceList from '../../helpers/priceList';
import { BsFillArrowUpCircleFill, BsFillArrowDownCircleFill, BsFillDashCircleFill } from 'react-icons/bs';
import moment from 'moment';
import 'moment/locale/tr';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

var img1 = require('./../../images/background/bg-5.png');

const AllPrices = () => {
    const [price, setPrice] = useState([{s:"",a:0,b:0,priceDateTimeUtc:"",direction:0,changePercent:0}]);
    const {t} = useTranslation()

    useEffect(() => {
        function loadScript(src) {
            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/masonary.js');

        priceList(process.env.REACT_APP_NAME).then((response) => {
            if (response) {
                setPrice(response)
            }
        })

        setInterval(() => {
            priceList(process.env.REACT_APP_NAME).then((response) => {
                if (response) {
                    setPrice(response)
                }
            })
        }, 5000)

    }, [])

    return (
        <>
            <div className="section-full p-t80 p-b50 bg-light mobile-page-padding bg-moving" style={{ backgroundImage: 'url(' + img1.default + ')' }}>
                <div className="container">
                    <div className="section-head">
                        <div className="mt-separator-outer separator-center">
                            <div className="mt-separator">
                                <h2 className="sep-line-one "><span className="text-primary"></span> <span className="font-weight-400">{t('Piyasalar')}</span></h2>
                            </div>
                        </div>
                    </div>
                    <div className="section-content">
                        <div className="row m-r10 m-l10">
                            <div id="no-more-tables">
                                <table className="table-striped table-condensed cf mt-responsive-table bg-white">
                                    <thead className="cf">
                                        <tr>
                                            <th></th>
                                            <th>{t('Sembol')}</th>
                                            <th>{t('Satış')}</th>
                                            <th>{t('Alış')}</th>
                                            <th>{t('Değişim')}</th>
                                            <th className='text-right'><span className='m-r50'>{t('Son Güncelleme')}</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {price.map((item) => {
                                            return (
                                                <tr key={item.s}>
                                                    <td data-title="Durum">
                                                        {item.direction > 0 && item.changePercent > 0 ? <BsFillArrowUpCircleFill color='#0d0' size={19} /> : null}
                                                        {item.changePercent === 0 ? <BsFillDashCircleFill color='#777' size={19} /> : null}
                                                        {item.direction < 0 && item.changePercent > 0 ? <BsFillArrowDownCircleFill color='#d00' size={19} /> : null}
                                                    </td>
                                                    <td data-title="Symbol"><b>{item.s}</b></td>
                                                    {item.direction > 0 && item.changePercent > 0 ? <td data-title="Alış" style={{ color: '#0d0' }}>{item.a}</td> : null}
                                                    {item.changePercent === 0 ? <td data-title="Alış" style={{ color: '#777' }}>{item.a}</td> : null}
                                                    {item.direction < 0 && item.changePercent > 0 ? <td data-title="Alış" style={{ color: '#d00' }}>{item.a}</td> : null}

                                                    {item.direction > 0 && item.changePercent > 0 ? <td data-title="Satış" style={{ color: '#0d0' }}>{item.b}</td> : null}
                                                    {item.changePercent === 0 ? <td data-title="Satış" style={{ color: '#777' }}>{item.b}</td> : null}
                                                    {item.direction < 0 && item.changePercent > 0 ? <td data-title="Satış" style={{ color: '#d00' }}>{item.b}</td> : null}

                                                    {item.direction > 0 && item.changePercent > 0 ? <td data-title="Değişim" style={{ color: '#0d0' }}>+{parseFloat(item.changePercent).toFixed(2)} %</td> : null}
                                                    {item.changePercent === 0 ? <td data-title="Değişim" style={{ color: '#777' }}><span >{item.changePercent} %</span></td> : null}
                                                    {item.direction < 0 && item.changePercent > 0 ? <td data-title="Değişim" style={{ color: '#d00' }}>-{parseFloat(item.changePercent).toFixed(2)} %</td> : null}
                                                    <td data-title="Son Güncelleme" className='text-right'><span className='m-r50'>{moment.utc(item.priceDateTimeUtc).local().format('L - LTS')}</span></td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AllPrices;