import React from 'react'
import { useTranslation } from 'react-i18next'
import Footer from '../Common/Footer'
import Header from '../Common/Header'
import AboutUs from '../Elements/AboutUs'
import Banner from '../Elements/Banner'
import About from './About'
import { useEffect } from 'react'
var bnrimg = require('./../../images/wolf/banner/about-us.webp');

function PrivacyPolicy() {
    const { t } = useTranslation()

    useEffect(() => {
        { document.title = t("Gizlilik Politikası - Wolf Markets - Benzersiz Ve Güvenilir Yatırım Partneri") }
    }, [])

    return (
        <>
            <Header />
            <div className="page-content">
                <Banner title={t("Web Sitesi'ne girilen bilgilerin güvenliği açısından Kurumumuz sistem ve internet altyapısı en güvenilir seviyede tutularak gerekli önlemler alınmıştır.")} pagename={t('Privacy Policy')} bgimage={bnrimg.default} />
                <div style={{ marginLeft: '5rem', marginRight: '5rem' }} className='text-center'>
                    <h4 style={{ marginTop: '4rem' }}>{t('GİZLİLİK POLİTİKASI')}</h4>
                    <p>{t("Wolf Markets müşteri memnuniyeti ve güvenliği odaklı hizmet felsefesi ile yatırım kuruluşlarının faaliyetleri gereği, işbu web sitesi www.wolf-markets.com vermiş olduğunuz bilgilerin gizliliğini korumak amacıyla aşağıda belirtilen temel kuralları benimsemektedir.")}</p>
                    <p>{t("Web Sitesi'ne girilen bilgilerin güvenliği açısından Kurumumuz sistem ve internet altyapısı en güvenilir seviyede tutularak gerekli önlemler alınmıştır. Müşterilerimizin Web Sitemiz 'e ürün/hizmet başvurusu ve bilgi güncelleme amaçlı girmiş oldukları bilgiler diğer internet kullanıcıları tarafından görüntülenemez. Müşterilerimizin bizimle paylaştığı bilgilere izinsiz ulaşım, Wolf Markets personeli dahil olmak üzere kısıtlanmıştır. Wolf Markets, söz konusu bilgileri müşterilerin onayı dışında ya da yasal bir yükümlülük altında bulunmadığı sürece herhangi bir üçüncü şahıs, kurum ve kuruluş ile paylaşmayacaktır.")}</p>
                    <p>{t("Wolf Markets, bu bilgileri sadece gerekli yetkiler ve yasal düzenlemeler çerçevesinde açıklayabilecektir. Wolf Markets'in tabi olduğu düzenleyici kurumlar velveya yasama, yürütme organ ve mercileri müşteri bilgilerinin açıklanmasını istediğinde, Wolf Markets, bu bilgileri yalnızca gerekli yetkiler çerçevesinde açıklayacaktır.")}</p>
                    <p>{t("Web Sitemiz diğer web sitelerine link vermektedir. Gizlilik Politikamız 'da yer alan taahhütlerimiz sadece web sitemiz içerisinde geçerlidir ve diğer web sitelerini kapsamamaktadır. Web Sitemiz 'den link ile gidilecek diğer web sitelerindeki kullanım ile ilgili o sitelere ait gizlilik güvencesi ve kullanım şartları geçerlidir. Web sitemizden reklam, banner, içerik maksatlı veya başka bir maksat ile geçilen diğer web sitelerinin bilgi kullanımı, etik ilkeleri, gizlilik prensipleri, nitelik ve servis kalitesi ile bu sitelerde oluşabilecek herhangi bir maddi I manevi zarar ve kayıplardan Wolf Markets sorumlu değildir. Wolf Markets, destek hizmetler almak amacıyla farklı kuruluşlarla çalışılması durumunda, bu firmaların Kurumun gizlilik standartlarına ve şartlarına uymaları şart koşulacaktır.")}</p>
                    <p>{t("Web sitemizde yer alan bilgi, materyal ve bunların düzenlenmesi konusundaki telif hakları, Wolf Markets'a aittir. Web Sitemiz 'in içerdiği üçüncü şahıslara ait materyaller dışında kalan bilgi ve materyallere dair tüm telif hakları, tescilli marka,patent, entelektüel ve diğer mülkiyet hakları Kurumumuza aittir.")}</p>
                    <p>{t("Wolf Markets ile paylaşacağınız şahsi bilgilerinizi (adres, telefon, faks, e-posta adresi vb.) sadece işlemlerinizin gerçekleşmesi ve işlemler ile ilgili hizmetlerin verilmesi ile kampanya, tanıtım, duyuru, değişiklik, bilgilendirme, kullanım ve bakım hizmetleri ile sözleşmenin yenilenmesine ilişkin iletileri göndermek için kullanılacaktır. Söz konusu bilgiler ve Web Sitesi üzerinden varlıklarınız ve varlıklarınıza ilişkin verilen talimatlar ve işlemler dahil her türlü bilgi yasal zorunluluklar veya izniniz haricinde kesinlikle üçüncü şahıs ve kurumlarla paylaşılmayacaktır.")}</p>
                    <p>{t("Müşterilerimizin kişisel bilgilerinin gizliliğini korumak amacıyla Kurumumuz sistem ve internet altyapısı en güvenilir seviyede tutularak gerekli önlemler alınmıştır. Ek olarak bilgi alınmak istenen konularda Kurumumuza danışmakta tereddüt etmeyiniz.")}</p>
                    <p>{t("Saygılarımızla,")}</p>
                    <p style={{ fontWeight: 'bold' }}>{t("Wolf Markets Menkul Değerler A.Ş")}</p>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default PrivacyPolicy