import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useTranslation } from 'react-i18next';

const images = [
    require('./../../images/gallery/about-mini.webp')
]

const AboutHome = () => {
    const {t} = useTranslation()

    useEffect(() => {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/masonary.js');

    }, [])
    const options = {
        loop: false,
        autoplay: false,
        margin: 30,
        nav: false,
        dots: false,
        navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
        responsive: {
            0: {
                items: 1
            },
            991: {
                items: 1
            }
        }
    };
    return (
        <>
            <div className="section-full mobile-page-padding p-t80 p-b30 bg-gray bg-repeat square_shape2">
                <div className="container">
                    <div className="section-head">
                        <div className="mt-separator-outer separator-center">
                            <div className="mt-separator">
                                <h1 className="text-white sep-line-one h1"><span className="font-weight-400 text-black">{t('Hakkında')} </span><span className='text-primary'>Wolf Markets </span></h1>
                            </div>
                        </div>
                    </div>
                    <div className="section-content">
                        <div className="row">
                            <div className="col-md-9 col-sm-12">
                                <OwlCarousel className="owl-carousel about-home owl-btn-vertical-center" {...options}>
                                    {images.map((item, index) => (
                                        <div className="item" key={index}>
                                            <div className="mt-img-effect zoom-slow">
                                                <NavLink to="/about"><img src={item.default} alt="about" /></NavLink>
                                            </div>
                                        </div>

                                    ))}

                                </OwlCarousel>
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <div className="about-home-right bg-white p-a30">
                                    <h3 className="m-t0 font-weight-100">{t("Neden Wolf Markets'i Seçmelisiniz?")}</h3>
                                    <p><strong>{t("Wolf Markets, güçlü ve teknolojik altyapısı ile güvenilir bir aracı kurumdur. Avrupa'da 8 ülkeden kullanıcısı olan Wolf Markets, hızlı işlem ağı sayesinde kullanıcı dostu bir platform olmayı da ihmal etmemiştir.")}</strong></p>
                                    <p>{t('Wolf Markets ile alım satım işlemlerinizi sorunsuz bir şekilde halledebilir; Sağlanan güncel destekler ile yatırım fırsatlarını değerlendirebilirsiniz. Sağladığı işlem hacmi de göz ardı edilmemelidir.')}</p>
                                    <div className="text-right">
                                        <a href="/about" className="site-button-link">{t('Daha Fazla Bilgi ')}<i className="fa fa-angle-right arrow-animation" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hilite-title text-left p-l50 text-uppercase hilite-dark">
                    <br></br>
                    <br></br>
                    <br></br>
                    <strong>Wolf Markets</strong>
                </div>
            </div>
        </>
    );
};

export default AboutHome;