import React from 'react'
import { useTranslation } from 'react-i18next'
import Footer from '../Common/Footer'
import Header from '../Common/Header'
import Banner from '../Elements/Banner'
import { useEffect } from 'react'

var bnrimg = require('./../../images/wolf/banner/about-us.webp');


function WhatisForex() {
  const { t } = useTranslation()

  useEffect(() => {
    { document.title = t("Döviz Alım-Satım - Wolf Markets - Benzersiz Ve Güvenilir Yatırım Partneri") }
  }, [])

  return (
    <>
      <Header />
      <div className="page-content">
        <Banner title={t('Döviz Alım-Satım')} pagename={t('Döviz Alım-Satım')} bgimage={bnrimg.default} />
        <div style={{ marginLeft: '5rem', marginRight: '5rem' }} className='text-center'>
          <h4 style={{ marginTop: '4rem' }}>{t('Döviz Alım-Satımı : Küresel Piyasalarda Değişim Fırsatları')}</h4>
          <p>{t('Döviz alım-satımı, Forex piyasasının (Foreign Exchange) ana unsuru olup yatırımcılara dünya genelinde farklı para birimlerinin değişen değerlerinden yararlanma şansı sunar. Bu dinamik piyasa, yatırımcılara serbestçe işlem yapma olanağı sağlar ve finansal fırsatlar sunar. İşte döviz alım-satımı hakkında daha fazla bilgi:')}</p>
          <h4 style={{ marginTop: '4rem' }}>{t('Forex Piyasasının İşleyişi')}</h4>
          <p>{t('Forex piyasası, elektronik bir ağ üzerinde işleyen merkezi olmayan bir piyasadır. Bu piyasa, dünya genelindeki birçok katılımcıyı bir araya getirir ve farklı para birimleri arasında değişim işlemlerine olanak tanır. Forex, hafta içi her gün 24 saat açık olan bir piyasadır ve coğrafi sınırlamalara tabi değildir.')}</p>
          <h4 style={{ marginTop: '4rem' }}>{t('Döviz Alım-Satımının Temel Mantığı')}</h4>
          <p>{t('Döviz alım-satımının temel mantığı, para birimlerinin değerindeki değişikliklerden yararlanmaktır. Yatırımcılar, bir para biriminin değer kazanacağını düşünerek o para birimini satın alır veya değer kaybedeceğini düşünerek satarlar. Örneğin, EUR/USD paritesinde Euro`nun Dolar karşısında değer kazanacağını tahmin eden bir yatırımcı, Euro alır. Ardından, Euro değer kazandığında Dolar karşılığına çevirir ve kar elde eder.')}</p>


          <h4 style={{ marginTop: '4rem' }}>{t('Kaldıraç ve Risk Yönetimi')}</h4>
          <p>{t('Forex piyasası, yatırımcılara kaldıraç kullanma fırsatı sunar. Kaldıraç, yatırımcıların yatırdıkları sermayenin üzerinde pozisyonlar açmalarına izin verir. Ancak bu, yüksek potansiyel getiri ile yüksek risk arasında bir dengeyi temsil eder. Bu nedenle, döviz alım-satımı yaparken risk yönetimi kritik bir rol oynar. Stop-loss emirleri gibi araçlar kullanarak riskleri sınırlamak önemlidir.')}</p>

          <h4 style={{ marginTop: '4rem' }}>{t('Temel ve Teknik Analiz')}</h4>
          <p>{t('Döviz alım-satımı yaparken yatırımcılar, temel analiz ve teknik analiz gibi iki ana analiz yöntemini kullanırlar. Temel analiz, ekonomik verileri, ülke politikalarını ve küresel olayları inceleyerek para birimi değerlerinin nasıl etkilenebileceğini tahmin etmeye çalışır. Teknik analiz ise grafikler, göstergeler ve geçmiş fiyat hareketlerine dayanarak gelecekteki fiyat hareketlerini tahmin etmeye çalışır. Her iki analiz türü de yatırımcılara piyasa hareketlerini daha iyi anlama konusunda yardımcı olabilir.')}</p>

          <h4 style={{ marginTop: '4rem' }}>{t('Yatırımın İleri Düzeyleri')}</h4>
          <p>{t('Döviz alım-satımı, yatırımcılar için sadece temel para birimi çiftleri üzerinden işlem yapma fırsatı sunmaz. Ayrıca daha karmaşık ticaret stratejileri, egzotik para birimleri ve diğer finansal araçlar üzerinde işlem yapma olanağı sağlar. Ancak bu tür ileri düzey işlemler daha fazla deneyim ve uzmanlık gerektirebilir.')}</p>

          <p>{t('Döviz alım-satımı, dünya genelinde farklı para birimlerinin değişen değerlerinden yararlanma fırsatı sunan büyüleyici bir finansal piyasadır. Ancak yüksek kaldıraç kullanımı ve piyasa volatilitesi gibi faktörler nedeniyle risk içerir. Bu nedenle, döviz alım-satımına başlamadan önce iyi bir eğitim almak ve riskleri yönetmek önemlidir. Forex, bilgi sahibi ve disiplinli yatırımcılar için önemli yatırım fırsatları sunar.')}</p>


        </div>
      </div>
      <Footer />
    </>
  )
}

export default WhatisForex