import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from '../components/Pages/Home';
import About from '../components/Pages/About';
import AllPrice from '../components/Pages/AllPrice';
import Error from '../components/Pages/Error';
import ContactUs from '../components/Pages/ContactUs';
import PrivacyPolicy from '../components/Pages/PrivacyPolicy';
import ProtectionPage from '../components/Pages/ProtectionPage';
import WhatisForex from '../components/Pages/WhatisForex';
import ForexEducation from '../components/Pages/ForexEducation';
import ForexAnalytics from '../components/Pages/ForexAnalytics';
import RiskAlert from '../components/Pages/RiskAlert';
import CookiePolicy from '../components/Pages/CookiePolicy';
import ScrollToTop from '../components/Common/ScroolToTop';

const Router = () => {
    return (
        <BrowserRouter basename="/">
            <ScrollToTop />
            <div className="page-wraper">
                <Switch>
                    <Route path='/' exact component={Home} />
                    <Route path='/about' exact component={About} />
                    <Route path='/markets' exact component={AllPrice} />
                    <Route path='/error' exact component={Error} />
                    <Route path='/contactus' exact component={ContactUs} />
                    <Route path='/privacy-policy' exact component={PrivacyPolicy} />
                    <Route path='/what-is-exchange' exact component={WhatisForex} />
                    <Route path='/crypto-invest' exact component={ForexEducation} />
                    <Route path='/stock-invest' exact component={ForexAnalytics} />
                    <Route path='/risk-alert' exact component={RiskAlert} />
                    <Route path='/cookie-policy' exact component={CookiePolicy} />
                    <Route path='/kvkk' exact component={ProtectionPage} />
                    <Route component={Error} />
                </Switch>
            </div>
        </BrowserRouter>
    );
};

export default Router;