import React from 'react'
import { useTranslation } from 'react-i18next'
import Footer from '../Common/Footer'
import Header from '../Common/Header'
import Banner from '../Elements/Banner'
import { useEffect } from 'react'
var bnrimg = require('./../../images/wolf/banner/about-us.webp');

function RiskAlert() {
  const { t } = useTranslation()

  useEffect(() => {
    { document.title = t("Risk Uyarısı - Wolf Markets - Benzersiz Ve Güvenilir Yatırım Partneri") }
  }, [])

  return (
    <>
      <Header />
      <div className="page-content">
        <Banner title={t("Risk Uyarısı")} pagename={t('Risk Uyarısı')} bgimage={bnrimg.default} />
        <div style={{ marginLeft: '5rem', marginRight: '5rem' }} className='text-center'>
          <h4 style={{ marginTop: '4rem' }}>{t('Risk Uyarısı ve İş Şartları')}</h4>
          <p>{t("Şirketin resmi dili İngilizce'dir. Şirket faaliyetlerinin daha ayrıntılı bir açıklaması için lütfen sitenin İngilizce versiyonunu ziyaret edin. İngilizce dışındaki dillere tercüme edilen bilgiler sadece bilgi amaçlıdır ve yasal bir kuvveti yoktur, diğer dillerde verilen bilgilerin doğruluğundan Şirket sorumlu değildir.")}</p>
          <h4 style={{ marginTop: '4rem' }}>{t('YABANCI GEÇİCİ VE TÜREVLİ İŞLEMLER İÇİN RİSKLER AÇIKLAMASI')}</h4>
          <p>{t("Genel İş Koşullarına ek olarak yapılan bu kısa uyarı, tüm riskleri ve yabancı para ve türev içeren işlemlerin diğer önemli yönlerini belirtmeyi amaçlamamaktadır. Riskleri göz önüne alarak, girdiğiniz sözleşmelerin niteliğini, bu tür sözleşmelerin kapsamındaki bu ilişkilerin yasal yönlerini veya riske maruz kalma derecenizi bilmiyorsanız, söz konusu ürünlerin işlemlerini çözmemelisiniz. Yabancı para cinsinden ve türev işlemler yüksek risklidir, bu nedenle birçok insan için uygun değildir. Tecrübelerinizi, amaçlarınızı, finansal kaynaklarınızı ve diğer önemli faktörleri göz önünde bulundurarak bu tür işlemlerin sizin için ne kadar uygun olduğunu iyice değerlendirmelisiniz.")}</p>
          <h4 style={{ marginTop: '4rem' }}>{t('1. YABANCI CURRENCY VE TÜREV İLE FAALİYETLER')}</h4>
          <p><b>1.1 </b>{t("Kaldıraçlı alım satım, potansiyel karların büyütülmesi anlamına gelir; Aynı zamanda kayıpların büyütüldüğü anlamına gelir. Marj gereksinimi ne kadar düşük olursa, piyasa size karşı hareket ederse potansiyel zarar riski de o kadar yüksek olur. Bazen gerekli olan kenar boşlukları% 0,5 kadar az olabilir. Marj kullanarak alım satım yaparken, zararlarınızın ilk ödemenizi aşabileceğini ve başlangıçta yatırdığınızdan çok daha fazla para kaybetmenin mümkün olduğunu unutmayın. İlk marjın tutarı, döviz alım satım sözleşmelerinin veya türevlerinin değerleriyle karşılaştırıldığında küçük görünebilir, çünkü ticari işlem sırasında kaldıraç veya dişli etkisi kullanılır. Nispeten kayda değer olmayan piyasa hareketleri, yatırılan ya da sizin tarafınızdan yatırılan miktarlar üzerinde orantılı olarak artan bir etkiye sahip olacaktır.")}</p>
          <p>{t("Bu durum sizin için veya aleyhinize olabilir. Konumunuzu desteklerken, ilk marjın derecesinde ve Şirkete yatırılan para miktarında herhangi bir kayıp olabilir. Piyasa konumunuzun tersi yönde hareket etmeye başladıysa ve / veya gereken marjın miktarı artarsa, Şirket pozisyonu desteklemek için acilen ek miktarda para yatırmanızı isteyebilir. Ek miktarlarda para yatırma gerekliliğinin karşılanmaması, Şirketinizin pozisyonunuzun / pozisyonlarının kapatılması ile sonuçlanabilir ve bunlarla bağlantılı herhangi bir kayıp veya eksikliğin sorumluluğunu üstleneceksiniz.")}</p>
          <p><b>1.2 </b>{t('Riski düşüren Siparişler ve Stratejiler: Yerel yasalar tarafından izin veriliyorsa belirli siparişlerin (örneğin, "stop-stop" emirleri) veya maksimum zarar miktarını sınırlayan "stop-limit" emirlerinin verilmesi, piyasa durumu durumunda verimsiz olabilir bu tür emirlerin uygulanmasını imkansız kılar (örneğin, pazarın likidite olması durumunda). Konumların kombinasyonlarını kullanan herhangi bir strateji, örneğin "yayılma" ve "istila", "uzun" ve "kısa" konumlarla ortak olanlardan daha az riskli olmayabilir.')}</p>
          <h4 style={{ marginTop: '4rem' }}>{t('2. YABANCI GEÇİCİ VE TÜREVLERLE İŞLEMLERE ÖZEL EK RİSKLER')}</h4>
          <p><b>2.1 </b>{t("Sözleşmelere giriş şartları: Komisyoncunuzdan sözleşmelere girme koşulları ve bunlara bağlı yükümlülükler hakkında ayrıntılı bilgi edinmeniz gerekir (örneğin, şartlar çerçevesinde, herhangi bir varlığın bir teslimat çerçevesinde gerçekleştirilmesi veya kabul edilmesi yükümlülüğünü tahakkuk ettirebileceğiniz durumlar) vadeli işlem sözleşmesi veya bir seçenek olması durumunda, sona erme tarihleri ve seçeneklerin yerine getirilmesi için zaman sınırlamaları hakkında bilgi). Bazı şartlar altında, bir borsa veya takas odası, söz konusu varlığın pazarındaki değişiklikleri yansıtmak üzere, kararlaştırılmamış sözleşmelerin (grev bedeli dahil) gerekliliklerini değiştirebilir.")}</p>
          <p><b>2.2 </b>{t("Ticaretin askıya alınması veya sınırlandırılması. Fiyat korelasyonu: Bazı pazar durumları (örneğin likidite) ve / veya bazı pazarların çalışma kuralları (örneğin, sözleşmelere göre ticaretin askıya alınması, aylarca, fiyat değişikliklerinin sınırlarındaki fazlalık nedeniyle) riskini artırabilir. işlemlerin gerçekleştirilmesi veya kareleme / netleştirme pozisyonları zor veya imkansız hale geldiğinden, oluşan zararlar. Seçenekler satarsanız, kayıplar artabilir. İyi topraklanmış bir ara bağlantı her zaman varlığın fiyatları ile türev varlığın arasında bulunmaz. Bir varlığın referans fiyatının olmaması 'gerçeğe uygun değer' tahminini zorlaştırabilir.")}</p>
          <p><b>2.3 </b>{t("Yatırılan fonlar ve mülk: Ülkenizde veya yurtdışında bir operasyon yürütürken, özellikle bir işlem yapan firmanın iflas veya iflas edecekseniz, nakit para veya diğer varlıklar biçiminde yatırdığınız Güvenlik sınırları dahilinde, koruyucu araçlar hakkında bilgi sahibi olmalısınız. konu. Nakit ya da diğer varlıklarınızı ne ölçüde iade edebileceğinizi, Karşı Tarafın faaliyetlerini yürüttüğü mevzuat ve yerel ülke standartlarına göre düzenlenir.")}</p>
          <p><b>2.4 </b>{t("Komisyon ücretleri ve diğer masraflar: Herhangi bir esnaf katılmadan önce, tüm komisyon ücretleri, ödemeleri ve sizin tarafınızdan ödenmesi gereken diğer masraflar hakkında net bilgi almalısınız. Bu giderler net finansal sonucunuzu etkileyecektir (kar veya zarar).")}</p>
          <p><b>2.5 </b>{t("Diğer yetki alanlarındaki işlemler: Piyasalardaki işlemlerin, iç pazarınızla resmen bağlantılı olan pazarlar da dahil olmak üzere, diğer yargı bölgelerinde yapılması sizin için ek risklere neden olabilir. Bahsedilen piyasaların düzenlenmesi, yatırımcı koruma derecesi (sizden daha düşük bir koruma derecesi dahil) bakımından sizinkinden farklı olabilir. Yerel düzenleyici otoriteniz, düzenleyici otoriteler veya pazarlar tarafından işlem yaptığınız diğer yargı bölgelerinde belirlenen kurallara zorunlu olarak uyulmasını sağlayamamaktadır.")}</p>
          <p><b>2.6 </b>{t("Kur riski: Hesabınızın para biriminden farklı olarak yabancı para cinsinden olan sözleşmelerle yapılan işlemlerin karı ve zararı, sözleşme para biriminden hesap para birimine çevrildiğinde döviz kurundaki dalgalanmalardan etkilenir.")}</p>
          <p><b>2.7 </b>{t("Likidite riski: Likidite riski işlem yapma yeteneğinizi etkiler. İşlem yapmak istediğiniz zamanda CFD'nizin veya varlığınızın işlem görmemesi riski (bir zararı önlemek veya kar etmek). Ek olarak, CFD sağlayıcısına depozito olarak vermeniz gereken marj, sahip olduğunuz CFD'lerin altında yatan varlıkların değerindeki değişikliklere göre günlük olarak yeniden hesaplanır. Eğer bu yeniden hesaplama (yeniden değerleme) önceki günkü değere kıyasla değerinde bir düşüşe neden olursa, marjı tekrar ayarlamak ve zararı kapatmak için derhal CFD tedarikçisine nakit ödeme yapmanız gerekecektir. Ödemeyi yapamazsanız, CFD sağlayıcısı bu eyleme katılsanız da olmasanız da konumunuzu kapatabilir. Ardından, dayanak varlığın fiyatı geri kazanılsa bile, zararı karşılamanız gerekecektir. Eğer gerekli marjınız yoksa, bu pozisyonlardan biri o aşamada sizin için kar gösterse bile, tüm CFD pozisyonlarınızı tasfiye eden CFD sağlayıcıları vardır. Konumunuzu açık tutmak için, CFD sağlayıcısının ilgili marj çağrıları için gerekli olduğunda kendi takdirine bağlı olarak ek ödemeler (genellikle kredi kartınızdan) almasına izin vermeniz gerekebilir. Hızlı hareket eden, değişken bir pazarda, bu şekilde büyük bir kredi kartı faturasını kolayca yönetebilirsiniz.")}</p>
          <p><b>2.8 </b>{t(" 'Kaybı durdur' sınırları: Kayıpları sınırlandırmak için birçok CFD sağlayıcısı size 'zararı durdur' limitlerini seçme fırsatı sunar. Bu, seçtiğiniz bir fiyat sınırına ulaştığında konumunuzu otomatik olarak kapatır. Örneğin, 'stop loss' sınırının, hızlı fiyat hareketleri veya piyasa kapanmasının olduğu durumlarda etkisiz olduğu bazı durumlar vardır. Stop loss limitleri sizi daima kayıplardan koruyamaz.")}</p>
          <p><b>2.9 </b>{t("İcra riski: İcra riski, işlemlerin hemen gerçekleşemeyeceği gerçeğiyle ilişkilidir. Örneğin, siparişinizi verdiğiniz an ile işlem yapıldığı an arasında bir gecikme olabilir. Bu dönemde, piyasa size karşı hareket etmiş olabilir. Yani, siparişiniz beklediğiniz fiyattan gerçekleşmiyor. Bazı CFD sağlayıcıları piyasa kapalıyken bile işlem yapmanızı sağlar. Bu işlemlerin fiyatlarının, dayanak varlığın kapanış fiyatından büyük ölçüde farklı olabileceğini unutmayın. Birçok durumda, spread, piyasa açık olduğu zaman olduğundan daha geniş olabilir.")}</p>
          <p><b>2.10 </b>{t("Karşı taraf riski: Karşı taraf riski, CFD yayınlayan sağlayıcının (yani, karşı taraf tarafınız) temerrüde düşmesi ve finansal yükümlülüklerini yerine getirememesi riskidir. Fonlarınız CFD sağlayıcısının fonlarından düzgün bir şekilde ayrılmamışsa ve CFD sağlayıcısı finansal zorluklarla karşı karşıya kalırsa, sizin tarafınızdan herhangi bir parayı geri alamayacağınız bir risk vardır.")}</p>
          <p><b>2.11 </b>{t("Ticaret sistemleri: Her zamanki 'ses' ve elektronik ticaret sistemlerinin çoğu, yönlendirme emirleri, dengeleme işlemleri, tescil ve işlemleri silmek için bilgisayar cihazlarını kullanır. Diğer elektronik cihazlarda ve sistemlerde olduğu gibi, bunlar geçici arıza ve hatalı çalışmaya tabidir. Belli zararların geri ödenmesine ilişkin şansınız, ticaret sistemleri tedarikçisi, piyasalar, takaslar ve / veya işlem yapan firmalar tarafından belirlenen sorumluluk sınırlarına bağlı olabilir. Bu sınırlar değişebilir; bu konuda komisyoncunuzdan ayrıntılı bilgi almanız gerekir.")}</p>
          <p><b>2.12 </b>{t("Elektronik ticareti: Herhangi bir Elektronik Haberleşme Ağı kullanılarak gerçekleştirilen alım satım işlemleri, yalnızca normal 'açık devre' pazarındaki alım satım işlemlerinden değil aynı zamanda diğer elektronik alım satım sistemlerinin kullanıldığı alım satım işlemlerinden de farklı olabilir. Elektronik Haberleşme Ağında herhangi bir işlem gerçekleştirirseniz, donanım veya yazılımın çalışmasındaki bir başarısızlık riski de dahil olmak üzere bu sisteme özgü riskleri alırsınız. Sistem arızası aşağıdakilerle sonuçlanabilir: Siparişiniz talimatlara uygun olarak yerine getirilmeyebilir; bir emir hiç yerine getirilmeyebilir; Pozisyonlarınız hakkında sürekli bilgi almak veya teminat gerekliliklerini yerine getirmek mümkün olmayabilir.")}</p>
          <p><b>2.13 </b>{t("Tezgah Üstü işlemleri: Bazı yetki bölgelerinde, firmaların tezgah üstü işlemler yapmalarına izin verilmektedir. Acenteniz bu işlemlerin karşılığı olarak hareket edebilir. Bu tür işlemlerin özelliği, kapanış pozisyonlarının, değerlerin tahmin edilmesinin veya makul fiyatın veya riske maruz kalmanın belirlenmesinin karmaşıklığına veya imkansızlığına bağlıdır. Yukarıda belirtilen nedenlerden dolayı, bu işlemler artan risklerle ilişkilendirilebilir. Tezgahüstü işlemleri yöneten düzenleme daha az katı olabilir veya belirli bir düzenleme modu sağlayabilir. Bu tür işlemleri yapmadan önce bunlarla bağlantılı olan kural ve risklere aşina olmanız gerekecektir.")}</p>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default RiskAlert