import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useEffect } from 'react';
import connector from '../../helpers/connector';
import InputMask from 'react-input-mask';
import { useTranslation } from 'react-i18next';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function RegisterModal({ openModal, setOpenModal, text }) {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);
    const { t } = useTranslation()

    let brandType = 0;
    var formUrl = window.location.protocol + '//' + window.location.hostname + "/api/v1/Submit?brand=" + brandType;
    // let formUrl = `https://wolf.testmedici.com/api/v1/Submit?brand=${brandType}`

    const [name, setName] = useState("");
    const [surname, setSurname] = useState("")
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [validator, setValidator] = useState(false);
    const [isNull, setIsNull] = useState(false);
    const [kvkk, setKvkk] = useState(false);
    const [isSend, setIsSend] = useState(false);


    var data = {
        "firstName": name,
        "lastName": surname,
        "email": email,
        "phone": String(phone).replace(" ", "").replace("(", "").replace(")", "").replace(" ", "").replace(" ", ""),
        "approvedConditions": kvkk,
    }
    // var data = {
    //     domain: window.location.protocol + '//' + window.location.hostname,
    //     formSystemName: "RealAccountForm",
    //     formValues: {
    //         "name": name,
    //         "surname": surname,
    //         "email": email,
    //         "phone": String(phone).replace(" ", "").replace("(", "").replace(")", "").replace(" ", "").replace(" ", ""),
    //         "country": "Turkey",
    //         "countryCode": "+90",
    //         "approvedConditions": kvkk,
    //     },
    //     languageIsoCode: 'tr',
    //     siteSystemName: 'WolfMarkets',

    // }

    useEffect(() => {
        if (name === "" || surname === "" || email === "" || phone === "" || kvkk === false || phone.includes("_") || validator === false) {
            setIsNull(false)
        }
        else {
            setIsNull(true)
        }
    }, [name, surname, email, phone, validator, kvkk])

    function EmailChange(e) {
        const validEmail = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        let isOk = validEmail.test(String(e).toLowerCase())
        if (isOk) {
            setEmail(e);
            setValidator(true)
        }
        else {
            setEmail(e);
            setValidator(false)
        }
    }

    async function Submit() {
        setIsSend(true)
        connector(formUrl, data)
            .then(data => {
                if (data.success) {
                    NotificationManager.success('İsteğiniz Alındı! Ekibimiz en kısa süre içerisinde sizinle iletişime geçecektir.', '');
                    setIsSend(false);
                }
                else {
                    NotificationManager.error('Bir Hata Oluştu! Lütfen sayfayı yenileyip tekrar deneyin.', '');
                    setIsSend(false)
                }
            })
            .catch(err => {
                if (err.response) {
                    if (err.response.status === 404) {
                        NotificationManager.error('Bir Hata Oluştu! Lütfen sayfayı yenileyip tekrar deneyin.', '');
                        setIsSend(false)
                    }
                    else {
                        NotificationManager.success('İsteğiniz Alındı! Ekibimiz en kısa süre içerisinde sizinle iletişime geçecektir.', '');
                        setIsSend(false)
                    }
                }
                else {
                    NotificationManager.error('Bir Hata Oluştu! Lütfen sayfayı yenileyip tekrar deneyin.', '');
                    setIsSend(false)
                }
            })
        setName("");
        setSurname("");
        setEmail("");
        setPhone("");
    }

    let lsLanguage = localStorage.getItem("i18nextLng")

    return (
        <div>
            <NotificationContainer />
            <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="contact-nav-form p-a30">
                        <div className="contact-info   m-b30">
                            <div className="contact-form cons-contact-form" method="post" action="">
                                <div className="contact-one m-b30">
                                    <div className="section-head">
                                        <div className="text-center m-b30">
                                            <h2><span className="text-primary">{t(`${text}`)} </span><br /> <span className="font-weight-300">{t('Hesap Oluştur')}</span></h2>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <input name="username" type="text" required className="form-control" placeholder={t('İsim')}
                                            value={name} onChange={event => { setName(event.target.value) }} />
                                    </div>
                                    <div className="form-group">
                                        <input name="username" type="text" required className="form-control" placeholder={t('Soyisim')}
                                            value={surname} onChange={event => { setSurname(event.target.value) }} />
                                    </div>
                                    <div className="form-group">
                                        <InputMask mask="(999) 999 99 99" name="phone" type="text" className="form-control" required placeholder={t('Telefon Numarası')}
                                            value={phone} onChange={event => { setPhone(event.target.value) }} />
                                    </div>
                                    <div className="form-group">
                                        <input name="email" type="text" className="form-control" required placeholder="E-MAIL"
                                            value={email} onChange={event => { EmailChange(event.target.value) }} />
                                    </div>
                                    <div style={{ marginTop: '-4rem' }} className="form-group"  >
                                        <input type="checkbox" className="form-control" id="kvkk2" onClick={() => setKvkk(kvkk ? false : true)} />
                                        <label htmlFor="kvkk2" style={{ maxWidth: "270px" }}>
                                            {
                                                lsLanguage == "tr" ?
                                                    <span className='text-primary font-weight-300'><a href='/protection' target='_blank' style={{ textDecoration: "underline" }}>Kişisel Verilerin Korunması ve İşlenmesi Sözleşmesi</a>'ni' kabul ediyorum.</span>
                                                    :
                                                    <span className='text-primary font-weight-300'>I accept the Agreement on the <a href='/protection' target='_blank' style={{ textDecoration: "underline" }}>Protection and Processing of Personal Data.</a></span>
                                            }
                                        </label>
                                    </div>
                                    <div className="text-center m-t30">
                                        {isNull ?
                                            <button name="submit" type="submit" value="Submit" className="site-button btn-effect" onClick={() => { Submit() }}>{t('GÖNDER')}</button>
                                            :
                                            <button name="submit" type="submit" value="Submit" className="site-button btn-effect button-disabled" >{isSend ? `${t('GONDERİLİYOR')}` : `${t('GÖNDER')}`}</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}