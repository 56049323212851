import { t } from 'i18next';
import React, { useEffect } from 'react';
import { FcInspection, FcCurrencyExchange, FcMoneyTransfer } from 'react-icons/fc';

const steps = [
    {
        count: 1,
        title: `Gerçek Hesap Oluştur`,
        icon: <FcInspection size={75} />,
        description: 'Kısa sürede gerçek hesap oluşturarak oluşturun ve size özel olarak atanacak müşteri temsilcisi sizi arayacaktır.',
    },
    {
        count: 2,
        title: 'Hesabınıza Para Yatırın',
        icon: <FcMoneyTransfer size={75} />,
        description: '70 ödeme yöntemi ile onaylı hesabınıza para yatırın ve bonus fırsatından yararlanın.',
    },
    {
        count: 3,
        title: 'Hızlıca Yatırıma Başlayın',
        icon: <FcCurrencyExchange size={75} />,
        description: 'Her gün yayınladığımız 35 farklı dili analiz etme yeteneği. Takip ederek yatırım fırsatlarından yararlanın',
    }
]

var img1 = require('./../../images/background/bg-6.png');

const Step = () => {
    useEffect(() => {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/masonary.js');

    }, [])

    return (
        <>
            <div className="section-full mobile-page-padding p-b50">
                <div className="section-content">
                    <div className="Service-half-top p-t80  bg-dark bg-moving" style={{ backgroundImage: 'url(' + img1.default + ')' }}>
                        <div className="container">
                            <div className="section-head text-white">
                                <div className="mt-separator-outer separator-left">
                                    <div className="mt-separator">
                                        <h2 className="text-white sep-line-one "><span className="font-weight-300">{t('Adım Adım')}</span> <span className="text-primary">Wolf Markets</span></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="services-half-bottom">
                        <div className="container">
                            <div className="row">
                                {steps.map((item, index) => (
                                    <div className="col-md-4 col-sm-6" key={index}>
                                        <div className="mt-icon-box-wraper m-b30">
                                            <div className="relative icon-count-2 bg-gray p-a30 p-tb50">
                                                <span className="icon-count-number">{item.count}</span>
                                                <div className=" m-b15 text-primary scale-in-center">
                                                    {item.icon}
                                                </div>
                                                <div className="icon-content">
                                                    <h3 className="mt-tilte m-b25">{t(`${item.title}`)}</h3>
                                                    <p>{t(`${item.description}`)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Step;