import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FcNews, FcRatings, FcSurvey, FcDiploma2 } from "react-icons/fc";

var img1 = require('./../../images/background/bg-6.png');
var img2 = require('./../../images/background/line.png');

const Why = () => {

    const {t} = useTranslation()
    useEffect(() => {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/masonary.js');

    }, [])

    return (
        <>
            <div className="section-full p-t80 p-b80 bg-dark bg-repeat square_shape2 inner-page-padding bg-moving" style={{ backgroundImage: 'url(' + img1.default + ')' }}>
                <div className="container">
                    <div className="section-head">
                        <div className="mt-separator-outer separator-left">
                            <div className="mt-separator text-white">
                                <h2 className="text-uppercase sep-line-one ">
                                    <span className="font-weight-300">{t('NEDEN')}</span>
                                    <span className="text-primary"> Wolf Markets </span>
                                    <span className="font-weight-300">?</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="section-content our-story">
                        <div className="row p-b70">
                            <div className="col-md-3 col-sm-6">
                                <div className="our-story-pic-block">
                                    <div className="mt-media our-story-pic">
                                        <FcDiploma2 size={120} style={{ verticalAlign: "bottom" }} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9 col-sm-6">
                                <div className="mt-box our-story-detail bg-moving bg-cover" style={{ backgroundImage: 'url(' + img2.default + ')' }}>
                                    <h5 className="m-b20 text-white">{t('Wolf Markets, yatırım, yatırım yönetimi, sigorta ve kredi piyasası lisanslarına sahiptir.')}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="row p-b70">
                            <div className="col-md-3 col-sm-6">
                                <div className="our-story-pic-block">
                                    <div className="mt-media our-story-pic">
                                        <FcSurvey size={120} style={{ verticalAlign: "bottom" }} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9 col-sm-6">
                                <div className="mt-box our-story-detail bg-moving bg-cover text-left" style={{ backgroundImage: 'url(' + img2.default + ')' }}>
                                    <h5 className="m-b20">{t('İsveç Mali Denetimi ayrıca Alman Mali Denetimi ve İngiliz Mali Denetleme Organlarının çerçeve ve kurallarına tabidir.')}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="row p-b70">
                            <div className="col-md-3 col-sm-6">
                                <div className="our-story-pic-block">
                                    <div className="mt-media our-story-pic">
                                        <FcNews size={120} style={{ verticalAlign: "bottom" }} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9 col-sm-6">
                                <div className="mt-box our-story-detail bg-moving bg-cover" style={{ backgroundImage: 'url(' + img2.default + ')' }}>
                                    <h5 className="m-b20 text-white">{t('Fatura alımları, kurumsal krediler, yatırım yönetimi, leasing ve alacak tahsili gibi hizmetler sunuyoruz.')}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="row p-b70">
                            <div className="col-md-3 col-sm-6">
                                <div className="our-story-pic-block">
                                    <div className="mt-media our-story-pic">
                                        <FcRatings size={120} style={{ verticalAlign: "bottom" }} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9 col-sm-6">
                                <div className="mt-box our-story-detail bg-moving bg-cover text-left" style={{ backgroundImage: 'url(' + img2.default + ')' }}>
                                    <h5 className="m-b20">{t('Ayrıca, bankalarla aynı mevduat garantisi ile rekabetçi tasarruf arayan özel şahıslar veya şirketler için tasarruf hesaplarımız var.')}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Why;