import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import store from './store'
import './i18n/i18n';
import 'react-notifications/lib/notifications.css';

ReactDOM.render(
  <Provider store={store}><App /></Provider>,
  document.getElementById('root')
);