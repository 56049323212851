import React, { useState, useEffect, useRef } from 'react';
import GoogleMaps from "simple-react-google-maps"
import Header from './../Common/Header';
import Footer from '../Common/Footer';
import Banner from './../Elements/Banner';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import InputMask from 'react-input-mask';
import connector from "../../helpers/connector"
import { useTranslation } from 'react-i18next';

var bnrimg = require('./../../images/wolf/banner/contact-us.jpg');

var bgmap = require('./../../images/background/bg-map.png');
var bnr1 = require('./../../images/background/line.png');

const RegisterFrom = () => {
    let brandType = 0;
    var formUrl = window.location.protocol + '//' + window.location.hostname + "/api/v1/Submit?brand=" + brandType;
    // var formUrl = `https://wolf.testmedici.com/api/v1/Submit?brand=${brandType}`;

    const modal = useRef()
    const { t } = useTranslation()

    const [name, setName] = useState("");
    const [surname, setSurName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [validator, setValidator] = useState(false);
    const [isNull, setIsNull] = useState(false);
    const [kvkkContact, setKvkkContact] = useState(false);
    const [isSend, setIsSend] = useState(false);

    var data = {
        "firstName": name,
        "lastName": surname,
        "email": email,
        "phone": String(phone).replace(" ", "").replace("(", "").replace(")", "").replace(" ", "").replace(" ", "")
    }
    // var data = {
    //     domain: window.location.protocol + '//' + window.location.hostname,
    //     formSystemName: "RealAccountForm",
    //     formValues: {
    //         "name": name,
    //         "surname": surname,
    //         "email": email,
    //         "phone": String(phone).replace(" ", "").replace("(", "").replace(")", "").replace(" ", "").replace(" ", ""),
    //         "country": "Turkey",
    //         "countryCode": "+90",
    //         "approvedConditions": kvkkContact,
    //     },
    //     languageIsoCode: 'tr',
    //     siteSystemName: 'Wolf Markets',

    // }
    const modalTrigger = useRef(null);

    let oldScrollY = 0;

    let lsLanguage = localStorage.getItem("i18nextLng")
    
    const showOnHover = () => {
        modal.current.style.display = 'block';
    };
    
    const hideOnHoverOut = () => {
        modal.current.style.display = 'none';
    };
    
    useEffect(() => {
        const handleMouseOver = () => showOnHover();
        const handleMouseOut = () => hideOnHoverOut();
    
        const targetElement = modalTrigger.current; // modal'ı tetikleyen element
        targetElement.addEventListener('mouseover', handleMouseOver);
        targetElement.addEventListener('mouseout', handleMouseOut);
    
        return () => {
            targetElement.removeEventListener('mouseover', handleMouseOver);
            targetElement.removeEventListener('mouseout', handleMouseOut);
        };
    }, []);

    useEffect(() => {
        if (name === "" || email === "" || phone === "" || surname === "" || kvkkContact === false || phone.includes("_") || validator === false) {
            setIsNull(false)
        }
        else {
            setIsNull(true)
        }
    }, [name, surname, email, phone, validator, kvkkContact])

    function EmailChange(e) {
        const validEmail = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        let isOk = validEmail.test(String(e).toLowerCase())
        if (isOk) {
            setEmail(e);
            setValidator(true)
        }
        else {
            setEmail(e);
            setValidator(false)
        }
    }

    async function Submit() {
        setIsSend(true)
        connector(formUrl, data)
            .then(data => {
                if (data.success) {
                    NotificationManager.success('Mesajınız Alındı! Ekibimiz en kısa süre içerisinde sizinle iletişime geçecektir.', '');
                    setIsSend(false);
                }
                else {
                    NotificationManager.error('Bir Hata Oluştu! Lütfen sayfayı yenileyip tekrar deneyin.', '');
                    setIsSend(false)
                }
            })
            .catch(err => {
                if (err.response) {
                    if (err.response.status === 404) {
                        NotificationManager.error('Bir Hata Oluştu! Lütfen sayfayı yenileyip tekrar deneyin.', '');
                        setIsSend(false)
                    }
                    else {
                        NotificationManager.success('Mesajınız Alındı! Ekibimiz en kısa süre içerisinde sizinle iletişime geçecektir.', '');
                        setIsSend(false)
                    }
                }
                else {
                    NotificationManager.error('Bir Hata Oluştu! Lütfen sayfayı yenileyip tekrar deneyin.', '');
                    setIsSend(false)
                }
            })
        setName("");
        setSurName("");
        setEmail("");
        setPhone("");
    }

    return (
        <div className='modal-register'>
            <div className="form-modal" ref={modalTrigger}>
                {/* <div className="section-full m-5 inner-page-padding" style={{ backgroundImage: "url(" + bgmap.default + ")", width: "100%", backgroundSize: "cover" }}> */}
                <div className="section-full m-1 inner-page-padding" style={{ width: "100%" }}>
                    <div className="" style={{ width: "100%" }}>
                        <div className="section-content" >
                            <div className="row">
                                <div className="">
                                    <div className="contact-form cons-contact-form " method="post" action="">
                                        <div className="contact-one-modal contact-one">
                                            <div className="section-head section-head-modal text-center">
                                                <div className="mt-separator-outer2 separator-left">
                                                    <div className="mt-separator2">
                                                        <h2 className="sep-line-one">{t('Hesap Oluştur')}</h2>
                                                    </div>
                                                </div>
                                            </div>

                                            <div ref={modal} style={{ display: 'none' }} className='row d-none'>
                                                <div className="form-group col-md-3">
                                                    <input name="username" type="text" required className="form-control" placeholder={t('İsim')}
                                                        value={name} onChange={event => { setName(event.target.value) }} />
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <input name="username" type="text" required className="form-control" placeholder={t('Soyisim')}
                                                        value={surname} onChange={event => { setSurName(event.target.value) }} />
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <input name="email" type="text" className="form-control" required placeholder="E-MAIL"
                                                        value={email} onChange={event => { EmailChange(event.target.value) }} />
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <InputMask mask="(999) 999 99 99" name="phone" type="text" className="form-control" required placeholder={t('Telefon Numarası')}
                                                        value={phone} onChange={event => { setPhone(event.target.value) }} />
                                                </div>
                                                <div className="">
                                                    <input type="checkbox" className="" id="kvkkContact" onClick={() => { setKvkkContact(kvkkContact ? false : true) }} />
                                                    <label htmlFor="kvkkContact">
                                                        {
                                                            lsLanguage == "tr" ?
                                                                <span className='text-primary font-weight-300'><a href='/kvkk' target='_blank' style={{ textDecoration: "underline" }}>Kişisel Verilerin Korunması ve İşlenmesi Sözleşmesi</a>'ni' kabul ediyorum.</span>
                                                                :
                                                                <span className='text-primary font-weight-300'>I accept the Agreement on the <a href='/kvkk' target='_blank' style={{ textDecoration: "underline" }}>Protection and Processing of Personal Data.</a></span>
                                                        }
                                                    </label>
                                                </div>
                                                <div className="text-right">
                                                    {isNull ?
                                                        <button name="submit" type="submit" value="Submit" className="site-button btn-effect" onClick={() => { Submit() }}>{t('GÖNDER')}</button>
                                                        :
                                                        <button name="submit" type="submit" value="Submit" className="site-button btn-effect button-disabled" >{isSend ? `${t('GÖNDERİLİYOR')}` : `${t('GÖNDER')}`}</button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegisterFrom;